import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faBars,
  faClose,
  faLongArrowRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import BlogContext from "../context/blogContext";
import Loader from "../components/Loader";

const Header = () => {
  // mobile menu js
  const [showServiceMenu, setServiceMenu] = useState(false);
  const [showIndutryMenu, setIndutryMenu] = useState(false);
  const [showExploreMenu, setExploreMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // service menu
    $(".dropdown-btn-main-head").hover(
      function () {
        $(".mega-section-services").show();
      },
      function () {
        $(".mega-section-services").hide();
      }
    );
    $(".mega-section-services").hover(
      function () {
        $(".mega-section-services").show();
        $(".dropdown-btn-main-head").addClass("dropdown-services-btn-efft");
      },
      function () {
        $(".mega-section-services").hide();
        $(".dropdown-btn-main-head").removeClass("dropdown-services-btn-efft");
      }
    );
  }, []);

  useEffect(() => {
    // service menu
    $(".industries-dropdown-cont").hover(
      function () {
        $(".mega-section-industries").show();
      },
      function () {
        $(".mega-section-industries").hide();
      }
    );
    $(".mega-section-industries").hover(
      function () {
        $(".mega-section-industries").show();
        $(".industries-dropdown-cont").addClass("dropdown-services-btn-efft");
      },
      function () {
        $(".mega-section-industries").hide();
        $(".industries-dropdown-cont").removeClass(
          "dropdown-services-btn-efft"
        );
      }
    );
  }, []);

  useEffect(() => {
    // explore  more menu
    $(".explore-dropdown-menu").hover(
      function () {
        $(".mega-explore-menu").show();
      },
      function () {
        $(".mega-explore-menu").hide();
      }
    );
  }, []);

  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);
  const [isCloseButton, setCloseButton] = useState(false);

  const handleMenuClick = () => {
    setMobileMenuVisibility((prevState) => !prevState);
    setCloseButton((prevState) => !prevState);
  };

  useEffect(() => {
    const linkElement = document.querySelector(".manuMobckc");
    linkElement.addEventListener("click", handleMenuClick);
    return () => {
      linkElement.removeEventListener("click", handleMenuClick);
    };
  }, []);

  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 100) {
        setIsSticky(false);
      } else {
        setIsSticky(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // header for mobile isSticky
  const [isStickyMobile, setStickyMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setStickyMobile(true);
      } else {
        setStickyMobile(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    const initialCheck = setTimeout(() => {
      handleScroll();
    }, 500);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(initialCheck);
    };
  }, []);

  // site preloader js
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoaded(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const { headerFooterData } = useContext(BlogContext);

  return (
    <>
      {loading && <Loader />}
      {/* -------= site preloader =--------- */}
      {pageLoaded ? (
        <div>
          <div></div>
          <div className="loder-section left-section"></div>
          <div className="loder-section right-section"></div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <div className="loder-section left-section"></div>
          <div className="loder-section right-section"></div>
        </div>
      )}
      {/*----------- header for pc  -------------- */}
      <nav
        className={
          isSticky
            ? "header-main-navbar sticky navbar navbar-expand-lg white d-md-none d-lg-block d-sm-none d-none"
            : "header-main-navbar  white navbar navbar-expand-lg d-md-none d-lg-block d-sm-none d-none"
        }
      >
        <div className="container">
          <Link to="/" className="navbar-brand site-logo">
            <img src={headerFooterData.header_logo} alt="logo" />
          </Link>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item dropdown-btn-main-head">
                <a href="#" className="nav-link">
                  Services <FontAwesomeIcon icon={faChevronUp} />
                </a>
              </li>
              <li className="nav-item industries-dropdown-cont">
                <a href="#" className="nav-link">
                  Industries <FontAwesomeIcon icon={faChevronUp} />
                </a>
              </li>
              <li className="nav-item explore-dropdown-menu">
                <a href="#" className="nav-link">
                  Explore More
                </a>

                <div className="mega-explore-menu">
                  <div>
                    <Link to="/about-beno" class="dropdown-item">
                      About Beno &nbsp;{" "}
                      <FontAwesomeIcon icon={faLongArrowRight} />
                    </Link>
                  </div>
                  <div>
                    <Link to="/our-ceo" class="dropdown-item">
                      From Our CEO &nbsp;{" "}
                      <FontAwesomeIcon icon={faLongArrowRight} />
                    </Link>
                  </div>
                  <div>
                    <Link to="/technology-trends" class="dropdown-item">
                      Technology & Trends &nbsp;{" "}
                      <FontAwesomeIcon icon={faLongArrowRight} />
                    </Link>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/careers" className="nav-link">
                  Careers
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Contact" className="nav-link">
                  Contact
                </Link>
              </li>
              <li className="nav-item request-call-ctabtn-headli">
                <a
                  href={`tel:${headerFooterData.mobile_number}`}
                  className="nav-link request-call-ctabtn-head"
                >
                  {" "}
                  Request a call
                </a>
              </li>
            </ul>

            {/* mega menu dropdown for service */}
            <div className="container mega-section-services">
              <div className="row text-center mt-4">
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/bpo-service">
                    BPO Services <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/digital-transformation">
                    Digital Transformation{" "}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/it-consulting-support">
                    IT Consulting & Support{" "}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/cyber-security">
                    Cyber Security <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
              </div>
              <div className="row text-center mt-4">
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/cloud-services">
                    Cloud Services <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/software-services">
                    Software Services{" "}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/hr-talent-services">
                    HR & Talent Services{" "}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/digital-experience">
                    Digital Experience{" "}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
              </div>
            </div>

            {/* mega menu dropdown for industries */}
            <div className="container mega-section-industries">
              <div className="row text-center mt-4">
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/bfsi">
                    BFSI <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/it">
                    IT <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/ites">
                    ITES <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/retail">
                    Retail <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
              </div>

              <div className="row text-center mt-4">
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/education">
                    Education <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/logistics">
                    Logistics <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/technology">
                    Technology <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/health-care">
                    Health Care <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
              </div>

              <div className="row text-center mt-4">
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/e-commerce">
                    E-Commerce <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/manufacturing">
                    Manufacturing <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
                <div className="col-3 mega-menu-drop-data">
                  <Link to="/travel-tourism">
                    Travel and Tourism{" "}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* ----------- header for mobile and tablet -------------- */}
      <header
        className={
          isStickyMobile
            ? "mobile-menu-beno mobile-menu-sticky d-sm-block d-md-block d-lg-none"
            : "mobile-menu-beno d-sm-block d-md-block d-lg-none"
        }
      >
        <div className="mobile-menu-container">
          <div className="mobile-menu-bar">
            <Link to="/" className="mobile-menu-logo">
              <img src={headerFooterData.header_logo} alt="logo" />
            </Link>
            <button
              className={`meanmenu-reveal manuMobckc ${
                isCloseButton ? "meanclose" : ""
              }`}
              style={{
                right: "0px",
                left: "auto",
                textAlign: "center",
                textIndent: "0px",
                fontSize: "25px",
              }}
            >
              {isCloseButton ? (
                <FontAwesomeIcon icon={faClose} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </button>
            <nav
              className="mobile-mean-nav"
              style={{ display: isMobileMenuVisible ? "block" : "none" }}
            >
              <ul>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={() => setServiceMenu(!showServiceMenu)}
                  >
                    Services
                    <FontAwesomeIcon
                      style={{
                        float: "right",
                        transform: showServiceMenu
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                      icon={faChevronDown}
                    />
                  </a>
                  {showServiceMenu && (
                    <ul className="service-ul-cont">
                      <li>
                        <Link
                          to="/bpo-service"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          BPO Services{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/digital-transformation"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          Digital Transformation{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/it-consulting-support"
                          onClick={() => handleMenuClick()}
                        >
                          IT Consulting & Support{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/cyber-security"
                          onClick={() => handleMenuClick()}
                        >
                          Cyber Security{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="cloud-services"
                          onClick={() => handleMenuClick()}
                        >
                          Cloud Services{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/software-services"
                          onClick={() => handleMenuClick()}
                        >
                          Software Services{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/hr-talent-services"
                          onClick={() => handleMenuClick()}
                        >
                          HR & Talent Services{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/digital-experience"
                          onClick={() => handleMenuClick()}
                        >
                          Digital Experience{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => setIndutryMenu(!showIndutryMenu)}
                  >
                    Industries
                    <FontAwesomeIcon
                      style={{
                        float: "right",
                        transform: showIndutryMenu
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                      icon={faChevronDown}
                    />
                  </a>
                  {showIndutryMenu && (
                    <ul>
                      <li>
                        <Link
                          to="/bfsi"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          BFSI <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/it"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          IT <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/ites"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          ITES <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/retail"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          Retail <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/education"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          Education <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/logistics"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          Logistics <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/technology"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          Technology <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/health-care"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          Health Care{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/e-commerce"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          E-commerce <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/manufacturing"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          Manufacturing{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/travel-tourism"
                          className="dropdown-item"
                          onClick={() => handleMenuClick()}
                        >
                          Travel & Tourism{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => setExploreMenu(!showExploreMenu)}
                  >
                    Explore More{" "}
                    <FontAwesomeIcon
                      style={{
                        float: "right",
                        transform: showExploreMenu
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                      icon={faChevronDown}
                    />
                  </a>
                  {showExploreMenu && (
                    <ul>
                      <li>
                        <Link
                          to="/about-beno"
                          onClick={() => handleMenuClick()}
                        >
                          About Beno <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link to="/our-ceo" onClick={() => handleMenuClick()}>
                          From Our CEO{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/technology-trends"
                          onClick={() => handleMenuClick()}
                        >
                          Technology & Trends{" "}
                          <FontAwesomeIcon icon={faLongArrowRight} />
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item">
                  <Link
                    to="/careers"
                    className="nav-link"
                    onClick={() => handleMenuClick()}
                  >
                    Careers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/Contact"
                    className="nav-link"
                    onClick={() => handleMenuClick()}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
