import { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/css/layout/Home.css";
import { ToastContainer } from "react-toastify";
import "./assets/css/layout/ExploreCareer.css";
import "./assets/css/service/BpoServices.css";
import "./assets/css/service/DigitalTransformation.css";
import "./assets/css/service/AppServices.css";
import "./assets/css/service/ItSupport.css";
import "./assets/css/service/CyberSecurity.css";
import "./assets/css/service/Cloud.css";
import "./assets/css/service/HRTalent.css";
import "./assets/css/service/DigitalExperience.css";
import "./assets/css/BlogSingle.css";

import "./assets/css/Industry/BfsiIndustry.css";
import "./assets/css/Industry/HealthCare.css";
import "./assets/css/Industry/ItesIndustry.css";
import "./assets/css/Industry/EcommerceInd.css";
import "./assets/css/Industry/Retail.css";
import "./assets/css/Industry/Manufacturing.css";
import "./assets/css/Industry/LogisticMain.css";
import "./assets/css/Industry/Education.css";

import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Loader from "./components/Loader.js";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const TermsCondition = lazy(() => import("./pages/TermsCondition"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Sitemap = lazy(() => import("./pages/Sitemap"));
const About = lazy(() => import("./pages/AboutBeno"));

// Services
const BpoService = lazy(() => import("./pages/Services/Bpo/BpoService"));
const BpoSales = lazy(() => import("./pages/Services/Bpo/Sales"));
const Insurance = lazy(() => import("./pages/Services/Bpo/Insurance"));
const BpoHealth = lazy(() => import("./pages/Services/Bpo/BpoHealth"));

const DataOperationsInsights = lazy(() =>
  import("./pages/Services/Bpo/DataOperationsInsights")
);

const CustomerExperience = lazy(() =>
  import("./pages/Services/Dgt/CustomerExperience")
);
const DigitalTransformation = lazy(() =>
  import("./pages/Services/Dgt/DigitalTransformation")
);
const DigitalOperation = lazy(() =>
  import("./pages/Services/Dgt/DigitalOperation")
);
const DigitalConsulting = lazy(() =>
  import("./pages/Services/Dgt/DigitalConsulting")
);
const DigitalWorkforce = lazy(() =>
  import("./pages/Services/Dgt/DigitalWorkforce")
);

const ApplicationServices = lazy(() =>
  import("./pages/Services/ApplicationService/ApplicationServices")
);
const DesignStrategy = lazy(() =>
  import("./pages/Services/ApplicationService/DesignStrategy")
);
const WebAppDevelopment = lazy(() =>
  import("./pages/Services/ApplicationService/WebAppDevelopment")
);
const MobileAppDevelopment = lazy(() =>
  import("./pages/Services/ApplicationService/MobileAppDevelopment")
);
const ApplicationTransformation = lazy(() =>
  import("./pages/Services/ApplicationService/ApplicationTransformation")
);
const QualityEngineering = lazy(() =>
  import("./pages/Services/ApplicationService/QualityEngineering")
);
const DevOps = lazy(() => import("./pages/Services/ApplicationService/DevOps"));
const ApplicationLifecycle = lazy(() =>
  import("./pages/Services/ApplicationService/ApplicationLifecycle")
);

const ItSupport = lazy(() => import("./pages/Services/ItSupport/ItSupport"));
const NetworkInfraServices = lazy(() =>
  import("./pages/Services/ItSupport/NetworkInfraServices")
);
const SecurityServices = lazy(() =>
  import("./pages/Services/ItSupport/SecurityServices")
);
const ManagedIt = lazy(() => import("./pages/Services/ItSupport/ManagedIt"));

const CyberSecurity = lazy(() =>
  import("./pages/Services/CyberSecurity/CyberSecurity")
);
const IdentityAccessManag = lazy(() =>
  import("./pages/Services/CyberSecurity/IdentityAccessManag")
);
const ThreatDetectionResponse = lazy(() =>
  import("./pages/Services/CyberSecurity/ThreatDetectionResponse")
);
const SecurityRiskAdvisory = lazy(() =>
  import("./pages/Services/CyberSecurity/SecurityRiskAdvisory")
);
const CloudInfraSecurity = lazy(() =>
  import("./pages/Services/CyberSecurity/CloudInfraSecurity")
);

const Cloud = lazy(() => import("./pages/Services/CloudPage/Cloud"));
const AppDataMigrationServices = lazy(() =>
  import("./pages/Services/CloudPage/AppDataMigrationServices")
);
const ManagedCloudServices = lazy(() =>
  import("./pages/Services/CloudPage/ManagedCloudServices")
);

const HRTalent = lazy(() => import("./pages/Services/HRTalentPage/HRTalent"));
const HRTalentAquacious = lazy(() =>
  import("./pages/Services/HRTalentPage/HRTalentAquacious")
);
const HRContingent = lazy(() =>
  import("./pages/Services/HRTalentPage/HRContingent")
);
const HRConsult = lazy(() => import("./pages/Services/HRTalentPage/HRConsult"));

const DigitalExperience = lazy(() =>
  import("./pages/Services/DigitalExperiencePage/DigitalExperience")
);
const ContentMarketingDge = lazy(() =>
  import("./pages/Services/DigitalExperiencePage/ContentMarketingDge")
);
const UserExperience = lazy(() =>
  import("./pages/Services/DigitalExperiencePage/UserExperience")
);
const MultiCulturalMarketing = lazy(() =>
  import("./pages/Services/DigitalExperiencePage/MultiCulturalMarketing")
);
const WebsiteTransformation = lazy(() =>
  import("./pages/Services/DigitalExperiencePage/WebsiteTransformation")
);

// Industry
const Bfsi = lazy(() => import("./pages/Industry/Bfsi/Bfsi"));
const BpoInd = lazy(() => import("./pages/Industry/Bfsi/BpoInd"));
const CyberSecurityBfsi = lazy(() =>
  import("./pages/Industry/Bfsi/CyberSecurityBfsi")
);
const CloudBfsi = lazy(() => import("./pages/Industry/Bfsi/CloudBfsi"));
const HrTalentBfsi = lazy(() => import("./pages/Industry/Bfsi/HrTalentBfsi"));
const ManagedItBfsi = lazy(() => import("./pages/Industry/Bfsi/ManagedItBfsi"));

const TravelTourism = lazy(() =>
  import("./pages/Industry/TravelTourism/TravelTourism")
);
const BpoTravel = lazy(() =>
  import("./pages/Industry/TravelTourism/BpoTravel")
);
const DgeTravel = lazy(() =>
  import("./pages/Industry/TravelTourism/DgeTravel")
);
const ApsTravel = lazy(() =>
  import("./pages/Industry/TravelTourism/ApsTravel")
);

const HealthCare = lazy(() => import("./pages/Industry/HealthCare/HealthCare"));
const HealthCareBpo = lazy(() =>
  import("./pages/Industry/HealthCare/HealthCareBpo")
);
const HealthAppServices = lazy(() =>
  import("./pages/Industry/HealthCare/HealthAppServices")
);
const ManagedItSupportHealth = lazy(() =>
  import("./pages/Industry/HealthCare/ManagedItSupportHealth")
);
const HealthCyberSecurity = lazy(() =>
  import("./pages/Industry/HealthCare/HealthCyberSecurity")
);
const HealthCloud = lazy(() =>
  import("./pages/Industry/HealthCare/HealthCloud")
);
const HealthHrTalent = lazy(() =>
  import("./pages/Industry/HealthCare/HealthHrTalent")
);
const HealthDigitalExperience = lazy(() =>
  import("./pages/Industry/HealthCare/HealthDigitalExperience")
);

const Technology = lazy(() => import("./pages/Industry/Technology/Technology"));
const ItesService = lazy(() => import("./pages/Industry/Ites/ItesService"));
const ItesDgt = lazy(() => import("./pages/Industry/Ites/ItesDgt"));
const ItesManagItSupport = lazy(() =>
  import("./pages/Industry/Ites/ItesManagItSupport")
);
const ItesCyber = lazy(() => import("./pages/Industry/Ites/ItesCyber"));
const ItesCloud = lazy(() => import("./pages/Industry/Ites/ItesCloud"));

const EcomIndus = lazy(() => import("./pages/Industry/ECommerce/EcomIndus"));
const EcommerceBpo = lazy(() =>
  import("./pages/Industry/ECommerce/EcommerceBpo")
);
const EcommerceAppServices = lazy(() =>
  import("./pages/Industry/ECommerce/EcommerceAppServices")
);
const EcomManagedItSupport = lazy(() =>
  import("./pages/Industry/ECommerce/EcomManagedItSupport")
);
const EcomCyberSecurity = lazy(() =>
  import("./pages/Industry/ECommerce/EcomCyberSecurity")
);
const EcommerceCloud = lazy(() =>
  import("./pages/Industry/ECommerce/EcommerceCloud")
);
const EcommerceTalent = lazy(() =>
  import("./pages/Industry/ECommerce/EcommerceTalent")
);
const EcomDigExperience = lazy(() =>
  import("./pages/Industry/ECommerce/EcomDigExperience")
);

const RetailMain = lazy(() => import("./pages/Industry/Retails/RetailMain"));
const RetailBpo = lazy(() => import("./pages/Industry/Retails/RetailBpo"));
const RetailDgt = lazy(() => import("./pages/Industry/Retails/RetailDgt"));
const RetailAps = lazy(() => import("./pages/Industry/Retails/RetailAps"));
const RetailMis = lazy(() => import("./pages/Industry/Retails/RetailMis"));
const RetailCyber = lazy(() => import("./pages/Industry/Retails/RetailCyber"));
const RetailCloud = lazy(() => import("./pages/Industry/Retails/RetailCloud"));
const RetailhrTalent = lazy(() =>
  import("./pages/Industry/Retails/RetailhrTalent")
);

const Manufacturing = lazy(() =>
  import("./pages/Industry/Manufacturing/Manufacturing")
);
const ManufacturingDgt = lazy(() =>
  import("./pages/Industry/Manufacturing/ManufacturingDgt")
);
const ManufacturingAps = lazy(() =>
  import("./pages/Industry/Manufacturing/ManufacturingAps")
);
const ManufacturingManage = lazy(() =>
  import("./pages/Industry/Manufacturing/ManufacturingManage")
);
const ManufacturingCyber = lazy(() =>
  import("./pages/Industry/Manufacturing/ManufacturingCyber")
);
const ManufacturingCloud = lazy(() =>
  import("./pages/Industry/Manufacturing/ManufacturingCloud")
);
const ManufacturingHr = lazy(() =>
  import("./pages/Industry/Manufacturing/ManufacturingHr")
);

const LogisticMain = lazy(() =>
  import("./pages/Industry/Logistic/LogisticMain")
);
const LogisticBpo = lazy(() => import("./pages/Industry/Logistic/LogisticBpo"));

const EducationMain = lazy(() =>
  import("./pages/Industry/Education/EducationMain")
);
const EdAppService = lazy(() =>
  import("./pages/Industry/Education/EdAppService")
);
const ItMain = lazy(() => import("./pages/Industry/It/ItMain"));

// ExploreMore
const OurCEO = lazy(() => import("./pages/ExploreMore/OurCEO"));
const TechnologyTrends = lazy(() =>
  import("./pages/ExploreMore/TechnologyTrends")
);
const Career = lazy(() => import("./pages/Careers/Career.js"));
const CareerDetail = lazy(() => import("./pages/Careers/CareerDetail"));
const SingleBlog = lazy(() => import("./pages/Blogs/SingleBlog"));
const ScrollToTop = lazy(() => import("./layouts/ScrollToTop"));
const ItesDge = lazy(() => import("./pages/Industry/Ites/ItesDge"));
const TechHrTalent = lazy(() =>
  import("./pages/Industry/Technology/TechHrTalent")
);
const TechBpo = lazy(() => import("./pages/Industry/Technology/TechBpo"));
const VirtualExp = lazy(() =>
  import("./pages/Services/DigitalExperiencePage/VirtualExp")
);

// Industry Trends
const EducationDigitalAge = lazy(() =>
  import("./IndustryTrends/EducationDigitalAge.js")
);
const HealthcareTech = lazy(() => import("./IndustryTrends/HealthcareTech.js"));
const RemoteWork = lazy(() => import("./IndustryTrends/RemoteWork.js"));
const ShapingFuture = lazy(() => import("./IndustryTrends/ShapingFuture.js"));
const VrEntertain = lazy(() => import("./IndustryTrends/VrEntertain.js"));
const Fintech = lazy(() => import("./IndustryTrends/Fintech.js"));
const SingleBlog2 = lazy(() => import("./pages/Blogs/SingleBlog2"));
const PageNotFound = lazy(() => import("./components/PageNotFound.js"));
const BpoFinace = lazy(() => import("./pages/Services/Bpo/BpoFinance.js"));
const BpoLegelProcess = lazy(import("./pages/Services/Bpo/BpoLegalProcess.js"));

function App() {
  AOS.init({
    once: false,
    duration: 2000,
  });

  return (
    <>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/about-beno" element={<About />} />
            {/* ------------Explore and career pages routes ------------- */}
            <Route path="/our-ceo" element={<OurCEO />} />
            <Route path="/technology-trends" element={<TechnologyTrends />} />
            <Route path="/careers" element={<Career />} />
            <Route path="/career-detail/:jobId" element={<CareerDetail />} />
            {/* ------------service pages routes ------------- */}
            <Route path="/bpo-service" element={<BpoService />} />
            <Route path="/bpo-service/bpo-sales" element={<BpoSales />} />
            <Route path="/bpo-service/bpo-insurance" element={<Insurance />} />
            <Route path="/bpo-service/bpo-finance" element={<BpoFinace />} />
            <Route
              path="/bpo-service/bpo-health-care"
              element={<BpoHealth />}
            />
            <Route
              path="/bpo-service/bpo-data-operations-insights"
              element={<DataOperationsInsights />}
            />
            <Route
              path="/bpo-service/bpo-legel-process"
              element={<BpoLegelProcess />}
            />
            <Route
              path="/digital-transformation"
              element={<DigitalTransformation />}
            />
            <Route
              path="/digital-transformation/dgt-customer-experience"
              element={<CustomerExperience />}
            />
            <Route
              path="/digital-transformation/digital-operation"
              element={<DigitalOperation />}
            />
            <Route
              path="/digital-transformation/digital-consulting"
              element={<DigitalConsulting />}
            />
            <Route
              path="/digital-transformation/digital-workforce"
              element={<DigitalWorkforce />}
            />
            <Route
              path="/software-services"
              element={<ApplicationServices />}
            />
            <Route
              path="/software-services/design-strategy"
              element={<DesignStrategy />}
            />
            <Route
              path="/software-services/web-application-development"
              element={<WebAppDevelopment />}
            />
            <Route
              path="/software-services/mobile-application-development"
              element={<MobileAppDevelopment />}
            />
            <Route
              path="/software-services/application-transformation"
              element={<ApplicationTransformation />}
            />
            <Route
              path="/software-services/quality-engineering"
              element={<QualityEngineering />}
            />
            <Route path="/software-services/devOps" element={<DevOps />} />
            <Route
              path="/software-services/application-lifecycle"
              element={<ApplicationLifecycle />}
            />
            <Route path="/it-consulting-support" element={<ItSupport />} />
            <Route
              path="/it-consulting-support/managed-network-infra-services"
              element={<NetworkInfraServices />}
            />
            <Route
              path="/it-consulting-support/managed-security-services"
              element={<SecurityServices />}
            />
            <Route
              path="/it-consulting-support/managed-it-support"
              element={<ManagedIt />}
            />
            <Route path="/cyber-security" element={<CyberSecurity />} />
            <Route
              path="/cyber-security/managed-security-services"
              element={<IdentityAccessManag />}
            />
            <Route
              path="/cyber-security/security-assesment-and-planning"
              element={<ThreatDetectionResponse />}
            />
            <Route
              path="/cyber-security/application-security"
              element={<SecurityRiskAdvisory />}
            />
            <Route
              path="/cyber-security/network-protection"
              element={<CloudInfraSecurity />}
            />
            <Route path="/cloud-services" element={<Cloud />} />
            <Route
              path="/cloud-services/app-data-migration-services"
              element={<AppDataMigrationServices />}
            />
            <Route
              path="/cloud-services/managed-cloud-services"
              element={<ManagedCloudServices />}
            />
            <Route path="/hr-talent-services" element={<HRTalent />} />
            <Route
              path="/hr-talent/hr-process-outsourcing"
              element={<HRTalentAquacious />}
            />
            <Route
              path="/hr-talent/staffing-services"
              element={<HRContingent />}
            />
            <Route
              path="/hr-talent/hr-talent-consulting"
              element={<HRConsult />}
            />
            <Route path="/digital-experience" element={<DigitalExperience />} />
            <Route
              path="/digital-experience/digital-marketing-services"
              element={<ContentMarketingDge />}
            />
            <Route
              path="/digital-experience/user-experience-design"
              element={<UserExperience />}
            />
            <Route
              path="/digital-experience/multi-cultural-marketing-strategies"
              element={<MultiCulturalMarketing />}
            />
            <Route
              path="/digital-experience/website-transformation-localization"
              element={<WebsiteTransformation />}
            />
            <Route
              path="digital-experience/virtual-experience-design"
              element={<VirtualExp />}
            />
            {/* ------------industry pages routes ------------- */}
            <Route path="/bfsi" element={<Bfsi />} />
            <Route path="/bfsi/bpo" element={<BpoInd />} />
            <Route
              path="/bfsi/managed-it-support"
              element={<ManagedItBfsi />}
            />
            <Route
              path="/bfsi/cyber-security"
              element={<CyberSecurityBfsi />}
            />
            <Route path="/bfsi/cloud-services" element={<CloudBfsi />} />
            <Route path="/bfsi/hr-talent-service" element={<HrTalentBfsi />} />
            <Route path="/travel-tourism" element={<TravelTourism />} />
            <Route path="/travel-tourism/bpo" element={<BpoTravel />} />
            <Route
              path="/travel-tourism/digital-experience"
              element={<DgeTravel />}
            />
            <Route
              path="/travel-tourism/application-services"
              element={<ApsTravel />}
            />
            <Route path="/health-care" element={<HealthCare />} />
            <Route path="/health-care/bpo" element={<HealthCareBpo />} />
            <Route
              path="/health-care/application-services"
              element={<HealthAppServices />}
            />
            <Route
              path="/health-care/managed-it-support"
              element={<ManagedItSupportHealth />}
            />
            <Route
              path="/health-care/cyber-security"
              element={<HealthCyberSecurity />}
            />
            <Route path="/health-care/cloud" element={<HealthCloud />} />
            <Route path="/health-care/hr-talent" element={<HealthHrTalent />} />
            <Route
              path="/health-care/digital-experience"
              element={<HealthDigitalExperience />}
            />
            <Route path="/technology" element={<Technology />} />
            <Route path="/technology/bpo" element={<TechBpo />} />
            <Route path="/technology/hr-talent" element={<TechHrTalent />} />
            <Route path="/ites" element={<ItesService />} />
            <Route path="/ites/digital-transformation" element={<ItesDgt />} />
            <Route
              path="/ites/managed-it-support"
              element={<ItesManagItSupport />}
            />
            <Route path="/ites/cyber-security" element={<ItesCyber />} />
            <Route path="/ites/cloud" element={<ItesCloud />} />
            <Route path="/ites/digital-experience" element={<ItesDge />} />
            <Route path="/e-commerce" element={<EcomIndus />} />
            <Route path="/e-commerce/bpo" element={<EcommerceBpo />} />
            <Route
              path="/e-commerce/application-service"
              element={<EcommerceAppServices />}
            />
            <Route
              path="/e-commerce/managed-it-support"
              element={<EcomManagedItSupport />}
            />
            <Route
              path="/e-commerce/cyber-security"
              element={<EcomCyberSecurity />}
            />
            <Route path="/e-commerce/cloud" element={<EcommerceCloud />} />
            <Route path="/e-commerce/hr-talent" element={<EcommerceTalent />} />
            <Route
              path="/e-commerce/digital-experience"
              element={<EcomDigExperience />}
            />
            <Route path="/retail" element={<RetailMain />} />
            <Route path="/retail/bpo" element={<RetailBpo />} />
            <Route
              path="/retail/digital-transformation"
              element={<RetailDgt />}
            />
            <Route path="/retail/application-service" element={<RetailAps />} />
            <Route path="/retail/managed-it-support" element={<RetailMis />} />
            <Route path="/retail/cyber-security" element={<RetailCyber />} />
            <Route path="/retail/cloud" element={<RetailCloud />} />
            <Route path="/retail/hr-talent" element={<RetailhrTalent />} />
            <Route path="/manufacturing" element={<Manufacturing />} />
            <Route
              path="/manufacturing/digital-transformation"
              element={<ManufacturingDgt />}
            />
            <Route
              path="/manufacturing/application-service"
              element={<ManufacturingAps />}
            />
            <Route
              path="/manufacturing/managed-it-support"
              element={<ManufacturingManage />}
            />
            <Route
              path="/manufacturing/cyber-security"
              element={<ManufacturingCyber />}
            />{" "}
            <Route
              path="/manufacturing/hr-talent"
              element={<ManufacturingHr />}
            />
            <Route
              path="/manufacturing/cloud"
              element={<ManufacturingCloud />}
            />
            <Route path="/logistics" element={<LogisticMain />} />
            <Route path="/logistics/bpo" element={<LogisticBpo />} />
            <Route path="/it" element={<ItMain />} />
            <Route path="/education" element={<EducationMain />} />
            <Route
              path="/education/application-service"
              element={<EdAppService />}
            />
            <Route
              path="/education-digital-age"
              element={<EducationDigitalAge />}
            />
            <Route
              path="/healthcare-tech-innovations"
              element={<HealthcareTech />}
            />
            <Route
              path="/remote-work-transformation"
              element={<RemoteWork />}
            />
            <Route path="/future-of-ai" element={<ShapingFuture />} />
            <Route path="/vr-entertainment" element={<VrEntertain />} />
            <Route path="/fintech-reshaping" element={<Fintech />} />
            {/* ------------blogs pages routes ------------- */}
            <Route path="/blog-details" element={<SingleBlog />} />
            <Route path="/blog/:blog_id" element={<SingleBlog2 />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
        <Footer />
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
