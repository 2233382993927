import React, { useState, useEffect, useMemo } from "react";
import BlogContext from "./blogContext";
import axios from "axios";

const BlogContextProvider = ({ children }) => {
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [banner, setBanner] = useState([]);
  const [mainAbout, setMainAbout] = useState([]);
  const [pages, setPages] = useState([]);
  const [techLogo, setTechLogo] = useState([]);
  const [mainservices, setMainservices] = useState([]);
  const [headerFooter, setHeaderFooter] = useState("");
  const [serviceIndustry, setServiceIndustry] = useState([]);
  const [ourExpertise, setOurExpertise] = useState([]);
  const [faq, setFaq] = useState([]);
  const [homeAbout, setHomeAbout] = useState("");
  const [statistics, setStatistics] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [happens, setHappens] = useState([]);
  const [solution, setSolution] = useState([]);
  const [featureImage, setFeatureImage] = useState([]);

  // blogs

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/blogs",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setArray(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching blog data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // banner

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/banner",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setBanner(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // about

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/about-section",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setMainAbout(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // pages heading and para

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/page-section",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setPages(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // logo sections

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/partners-tech",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setTechLogo(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // main services

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/service-section",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setMainservices(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // header footer

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/setting/header-footer",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setHeaderFooter(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // industry we serve

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/industry-section",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setServiceIndustry(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // our expertise

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/our-expertise",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setOurExpertise(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // faq

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/cms-page",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setFaq(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // home about

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/home-about",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setHomeAbout(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // statictics

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/company-statistics",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setStatistics(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // cap and benefits

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/cap-benefits",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setBenefits(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // segment

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/segments",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setHappens(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // solutions

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/solution",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setSolution(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // feature image

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/featured-images",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setFeatureImage(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching banner data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const blogData = useMemo(() => array, [array]);
  const bannerData = useMemo(() => banner, [banner]);
  const mainAboutData = useMemo(() => mainAbout, [mainAbout]);
  const pagesData = useMemo(() => pages, [pages]);
  const techLogoData = useMemo(() => techLogo, [techLogo]);
  const mainServicesData = useMemo(() => mainservices, [mainservices]);
  const headerFooterData = useMemo(() => headerFooter, [headerFooter]);
  const serviceIndustryData = useMemo(() => serviceIndustry, [serviceIndustry]);
  const ourExpertiseData = useMemo(() => ourExpertise, [ourExpertise]);
  const faqData = useMemo(() => faq, [faq]);
  const homeAboutData = useMemo(() => homeAbout, [homeAbout]);
  const statisticsData = useMemo(() => statistics, [statistics]);
  const benefitsData = useMemo(() => benefits, [benefits]);
  const segmentData = useMemo(() => happens, [happens]);
  const solutionData = useMemo(() => solution, [solution]);
  const featureImages = useMemo(() => featureImage, [featureImage]);

  return (
    <BlogContext.Provider
      value={{
        array,
        loading,
        banner,
        blogData,
        bannerData,
        mainAboutData,
        pagesData,
        techLogoData,
        mainServicesData,
        headerFooterData,
        serviceIndustryData,
        ourExpertiseData,
        faqData,
        homeAboutData,
        statisticsData,
        benefitsData,
        segmentData,
        solutionData,
        featureImages,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export default BlogContextProvider;
