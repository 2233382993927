const IMG = {
  BpoUnderBack: require("../assets/images/main-site/under-back.png"),
  AboutJourneyBanner: require("../assets/images/main-site/about-journey-banner.png"),
  Shape121: require("../assets/images/home/shape-12-1.png"),
  Bg4: require("../assets/images/main-site/bg4.png"),
  ContactIconCall: require("../assets/images/main-site/whatsapp-icon.png"),
  HomeBgDot: require("../assets/images/home/home-bg-dot.png"),
  SitemapBanner: require("../assets/images/main-site/sitemap.jpg"),
  FooterPartners: require("../assets/images/home/footer-partners.png"),
  HomePlatformBg: require("../assets/images/home/home-platform-bg.png"),
  ContactIconEmail: require("../assets/images/main-site/dinot-icon.png"),
  HomeCertificateBg: require("../assets/images/home/home-certificate-bg.png"),
  ContactIconAddress: require("../assets/images/main-site/mapder.png"),
  ApproachArrow: require("../assets/images/application-services/approach-arrow.png"),
  Bullet: require("../assets/images/home/bullet.png"),
  RpoRecruitmentProcess: require("../assets/images/rpo/rpo-recruit-process.png"),
  ItSecurityBlog: require("../assets/images/industry/it/it-security-blog.jpg"),
  FooterPartners2: require("../assets/images/home/footer-partners2.png"),
  HomeContgactBg: require("../assets/images/home/home-contact-bg.png"),
  TopCustomers: require("../assets/images/dgt/Top-customers.png"),
  CeoNewImg: require("../assets/images/main-site/ceo-new-image.png"),
  ManageItBlog: require("../assets/images/manageItSupport/manageit-blog.avif"),
  DgeDoubleArrow: require("../assets/images/dgt-experience/dge-double-arrow.png"),
  MdSirImg: require("../assets/images/main-site/MDSir-beno.png"),
  DgeBulletArrow: require("../assets/images/dgt-experience/dge-arrow-bullet.png"),
  CloudBanner: require("../assets/images/cloud/cloud-banner-main.png"),
  AboutBfsi: require("../assets/images/industry/bfsi/about-bfsi.png"),
  RpoSourcingSpeacialist: require("../assets/images/rpo/rpo-sourcing-speacial.png"),
  RpoBuisnessDev: require("../assets/images/rpo/rpo-buisness-dev.png"),
  RpoFullLife: require("../assets/images/rpo/rpo-full-life.png"),
  RpoLeadGen: require("../assets/images/rpo/rpo-lead-gen.png"),
  RpoBenchSales: require("../assets/images/rpo/rpo-bench-sales.png"),
  RpoTechHiring: require("../assets/images/rpo/rpo-tech-hiring.png"),
  RpoRetainedSearch: require("../assets/images/rpo/rpo-retained-search.png"),
  RpoConsultingSearch: require("../assets/images/rpo/rpo-consulting-serv.png"),
  RpoThink1: require("../assets/images/rpo/rpo-think1.png"),
  RpoThink2: require("../assets/images/rpo/rpo-think2.png"),
  RpoThink3: require("../assets/images/rpo/rpo-think3.png"),
  RpoThink4: require("../assets/images/rpo/rpo-think4.png"),
  CloudBlog: require("../assets/images/cloud/cloud-blog.jpg"),
  BpoExpertise1: require("../assets/images/bpo/Layer_1.png"),
  BpoExpertise2: require("../assets/images/bpo/icon2.png"),
  BpoExpertise3: require("../assets/images/bpo/icon3.png"),
  InnerCyberSecurityThink: require("../assets/images/CyberSecurity/under-meeting.png"),
  HrOperations: require("../assets/images/bpo/HR-Operations.png"),
  OurCeoBannerNew: require("../assets/images/main-site/our-ceo-banner-new.png"),
  Management: require("../assets/images/bpo/managment.jpg"),
  CloudDevIcon1: require("../assets/images/cloud/cloud-devops-icon1.png"),
  CloudDevIcon2: require("../assets/images/cloud/cloud-devops-icon2.png"),
  CloudDevIcon3: require("../assets/images/cloud/cloud-devops-icon3.png"),
  CloudDevIcon4: require("../assets/images/cloud/cloud-devops-icon4.png"),
  CloudDevIcon5: require("../assets/images/cloud/cloud-devops-icon5.png"),
  CloudDevIcon6: require("../assets/images/cloud/cloud-devops-icon6.png"),
  CloudDevIcon7: require("../assets/images/cloud/cloud-devops-icon7.png"),
  CloudDevIcon8: require("../assets/images/cloud/cloud-devops-icon8.png"),
  CloudDevIcon9: require("../assets/images/cloud/cloud-devops-icon9.png"),
  InsurenceRenewel: require("../assets/images/bpo/insurance-renewel.png"),
  TechTrendBlogAvatar: require("../assets/images/main-site/tech-trends-blog-avatar.png"),
  TalentBannerImg: require("../assets/images/bpo/talent-banner-image.jpg"),
  OutstandingBpo: require("../assets/images/bpo/outstanding-bpo.png"),
  CyberSecurityServiceIdentityAccessManagement: require("../assets/images/CyberSecurity/it-industry-first.png"),
  BfsiBpoBlog: require("../assets/images/industry/bfsi/bfsi-bpo-blog.jpg"),
  InnerCyberSecurityThinkStories1: require("../assets/images/CyberSecurity/Maximum-Efficiency.png"),
  InnerCyberSecurityThinkStories2: require("../assets/images/CyberSecurity/Most-Valuable.png"),
  BfsiBLog: require("../assets/images/industry/bfsi/bfsi-blog.jpg"),
  CareersDetailsBanner: require("../assets/images/main-site/career-details-page-banner.jpg"),
  AgileBanner: require("../assets/images/application-services/agile-banner.jpeg"),
  technologyBlog: require("../assets/images/industry/technology/technology-blog.jpg"),
  CyberBlog: require("../assets/images/CyberSecurity/cyber-blog.avif"),
  CyberSecurityServiceThreatDetectionResponse: require("../assets/images/CyberSecurity/it-industry-second.png"),
  AgileWhat: require("../assets/images/application-services/agile-what.png"),
  RetailBlog: require("../assets/images/industry/retails/retail-blog.jpg"),
  HomeBlog1: require("../assets/images/home/blog-last-one.png"),
  CloudAbout: require("../assets/images/cloud/cloud-about.png"),
  HrTalentManufacturingChallenges: require("../assets/images/hr-talent/hr-talent-manufacturing-challenges.jpg"),
  DesignStrategyWhatWeThink: require("../assets/images/application-services/design-strategy-what-we-think.png"),
  HomeBlog2: require("../assets/images/home/blog-last-two.png"),
  HrTalentManufacturingBanner: require("../assets/images/hr-talent/hr-talent-manufacturing.jpg"),
  BfsiCloudBlog: require("../assets/images/industry/bfsi/bfsi-cloud-blog.jpg"),
  BpoBlogLatest: require("../assets/images/bpo/latest-single-one.jpg"),
  BfsiHrTalentBlog: require("../assets/images/industry/bfsi/bfsi-hr-talent-blog.jpg"),
  BfsiManageItBlog: require("../assets/images/industry/bfsi/bfsi-manage-it-blog.jpg"),
  EcomBlog: require("../assets/images/industry/ecommerce/ecom-blog.jpg"),
  HomeBlog3: require("../assets/images/home/blog-last-three.png"),
  EducationApsFaq1: require("../assets/images/industry/education/education-aps-faq1.jpg"),
  EducationApsFaq2: require("../assets/images/industry/education/education-aps-faq2.jpg"),
  EducationApsFaq3: require("../assets/images/industry/education/education-aps-faq3.jpg"),
  EducationApsAbout2: require("../assets/images/industry/education/education-aps-about2.jpg"),
  EducationBlog: require("../assets/images/industry/education/education-blog.jpg"),
  HealthCareBlog: require("../assets/images/industry/health-care/health-care-blog.jpg"),
  ItBlog: require("../assets/images/industry/it/it-blog.jpg"),
  ItesManageItBlog: require("../assets/images/industry/ites/ites-manage-it-blog.jpg"),
  ItesBlog: require("../assets/images/industry/ites/ites-blog.jpg"),
  LogisticBlog: require("../assets/images/industry/logistics/logistic-blog.jpg"),
  HrTalentManufacturingAbout: require("../assets/images/hr-talent/hr-talent-manufacturing-about.jpg"),
  ManufacturingBlog: require("../assets/images/Manufacturing/manufacturing-blog.jpg"),
  HrTalentManufacturingFeatures: require("../assets/images/hr-talent/hr-talent-manufacturing-features.jpg"),
  HrTalentRetailBanner: require("../assets/images/hr-talent/hr-talent-retail-banner.png"),
  EducationApsFaq4: require("../assets/images/industry/education/education-aps-faq4.jpg"),
  InsurenceFirst: require("../assets/images/bpo/automated-transaction-processing.jpg"),
  AgileCorporateBt: require("../assets/images/application-services/agile-corporate-bt.png"),

  // ************************* too keep ************* //

  // HeaderLogo: require("../assets/images/footer/Beno-logo.png"),
  // FooterLogo: require("../assets/images/footer/white_logo-beno.png"),

  // HomeBanner: require("../assets/images/home/banner-img.png"),
  // HomeIndusTrends1: require("../assets/images/home/home-indus1.png"),
  // HomeIndusTrends2: require("../assets/images/home/home-indus2.png"),
  // HomeIndusTrends3: require("../assets/images/home/home-indus3.png"),
  // HomeIndusTrends4: require("../assets/images/home/home-indus4.png"),

  // HomeIndusServe1: require("../assets/images/home/home-serve1.png"),
  // HomeIndusServe2: require("../assets/images/home/home-serve2.png"),
  // HomeIndusServe3: require("../assets/images/home/home-serve3.png"),

  // ContactBanner: require("../assets/images/main-site/contact-banner.jpg"),
  // ContactOfficeLucknow: require("../assets/images/main-site/lucknow-branch.png"),
  // ContactOfficeBihar: require("../assets/images/main-site/bihar-branch.png"),
  // ContactOfficeMumbai: require("../assets/images/main-site/Mumbai-branch.png"),
  // ContactOfficeAhmedabad: require("../assets/images/main-site/ahmedabad-branch.jpg"),
  // ContactOfficeBengaluru: require("../assets/images/main-site/bengaluru-branch.jpg"),
  // CareersBanner: require("../assets/images/main-site/main-career-banner-img.jpg"),
  // CareersJobs: require("../assets/images/main-site/job-opening-sec-img.png"),
  // CEOBannerBg: require("../assets/images/main-site/our-ceo.jpg"),
  // // CEOBanner: require("../assets/images/main-site/ceo-man-banner.png"),
  // TechnologyBanner: require("../assets/images/main-site/tech-trends-banner.jpg"),
  // TechnologyTrendsBanner: require("../assets/images/main-site/technology-trend-bannerr.jpg"),
  // TechnologyAdmin: require("../assets/images/main-site/tech-trends-person.jpeg"),
  // PrivacyBanner: require("../assets/images/main-site/policy-banner.jpg"),
  // ConditionBanner: require("../assets/images/main-site/terms-condition.jpg"),

  // BpoBanner: require("../assets/images/bpo/bpo-banr-at.png"),

  // BpoServeBfsi: require("../assets/images/bpo/bfsi-bpo.jpg"),
  // BpoServeTravel: require("../assets/images/bpo/travel-tourism-bpo.jpg"),
  // BpoServeTravelUpd: require("../assets/images/bpo/bpo-travel-tourism-industry.jpg"),
  // BpoServeHealth: require("../assets/images/bpo/health-care-bpo.jpg"),
  // BpoServeTechnology: require("../assets/images/bpo/technology-bpo.jpg"),
  // BpoEcommerceIndustry: require("../assets/images/bpo/e-com-bpo-industry.png"),
  // BpoServeEcommerce: require("../assets/images/bpo/e-commerce.jpg"),
  // BpoServeRetails: require("../assets/images/bpo/retails-bpo.jpg"),
  // BpoServeLogistics: require("../assets/images/bpo/logistics-bpo.jpg"),

  // BpoServeCustomer: require("../assets/images/bpo/sales-customer-bpo.jpg"),
  // BpoServeTalent: require("../assets/images/bpo/talent-hr.png"),
  // BpoServeInsuranceHealth: require("../assets/images/bpo/insurance-helh-ws.png"),
  // BpoServeFinance: require("../assets/images/bpo/financebpo.jpg"),
  // BpoServeLifeSciences: require("../assets/images/bpo/insurance-do.png"),
  // BpoServeDataOpertations: require("../assets/images/bpo/industry-seven-bpo.png"),
  // BpoServeLegalProcess: require("../assets/images/bpo/legal-process-outs-bpo-service.jpg"),

  // BpoHappen1: require("../assets/images/bpo/outsourcing-what-do.jpg"),
  // BpoHappen2: require("../assets/images/bpo/why-happen-bpo.png"),
  // BpoHappen3: require("../assets/images/bpo/trans-happen-bpo.png"),
  // BpoHappen4: require("../assets/images/bpo/values-happen-bpo.png"),
  // BpoValueAddService: require("../assets/images/bpo/value-added-bpo-service.jpg"),

  // FinPageBanner: require("../assets/images/bpo/fin-page-banner.jpg"),
  // FinUnderstandSec: require("../assets/images/bpo/fin-understand-sec-img.png"),
  // PredictiveAnalytics: require("../assets/images/bpo/predective-analytics.jpg"),
  // FraudDetectionAndPrevention: require("../assets/images/bpo/fraud-detection-and-prevention.jpg"),
  // ExpenseManagement: require("../assets/images/bpo/expense-management.jpg"),

  // FinSecPaceCard: require("../assets/images/bpo/fin-pace-sec-card.png"),
  // HealthCareBanner: require("../assets/images/bpo/helth-care-banner.jpeg"),
  // DataOpInsight: require("../assets/images/bpo/bpo-data-operations-insights-banner.jpeg"),
  // InsurenceBilling: require("../assets/images/bpo/insurance-billing.png"),
  // InsurenceService: require("../assets/images/bpo/insurance-service.png"),
  // LegalBanner: require("../assets/images/bpo/legal-banner.jpg"),
  // OperationImage: require("../assets/images/bpo/operations-image.jpg"),
  // LpoBuisnessImage: require("../assets/images/bpo/LPO-Business-img.png"),
  // Revolutionizing: require("../assets/images/bpo/Revolutionizing.png"),
  // InsurenceBanner: require("../assets/images/bpo/insurance-banner.jpeg"),
  // InsurenceThink: require("../assets/images/bpo/insurance-think.png"),
  // ThinkCard1: require("../assets/images/bpo/think-card1.png"),
  // ThinkCard2: require("../assets/images/bpo/think-card2.png"),
  // ThinkCard3: require("../assets/images/bpo/think-card3.png"),
  // AccordionDgt: require("../assets/images/dgt/accodition-dgt.png"),
  // AgileImage: require("../assets/images/dgt/agile-image.png"),
  // ChangeLeaderShip: require("../assets/images/dgt/change-leadership.png"),
  // CustomDoSec: require("../assets/images/dgt/customer-do-sec.png"),
  // CustomBanner: require("../assets/images/dgt/cutomer_banner.jpeg"),
  // DcWhatDo: require("../assets/images/dgt/dc-what-do.png"),
  // DgoOnBoarding: require("../assets/images/dgt/dgo-onboarding.png"),
  // DgoWorkflowTraining: require("../assets/images/dgt/dgo-workflow-training.png"),
  // DgtAboutMain: require("../assets/images/dgt/dgt-about-main.png"),
  // DgtBannerMain: require("../assets/images/dgt/dgt-banner-main.png"),
  // DgtBannerBgImg: require("../assets/images/dgt/dgt-bnner-bg-img.jpg"),
  // DgtBlogLatestOne: require("../assets/images/dgt/blog-latest-one-dgt.jpg"),
  // DgtDcBanner: require("../assets/images/dgt/dgt-dc-banner.jpeg"),
  // DgtItesIndustry: require("../assets/images/dgt/ites-dgt-indus.jpg"),
  // DgtRetailIndustry: require("../assets/images/dgt/retail-insutry-dgt.jpg"),
  // DgtDigitalOperationService: require("../assets/images/dgt/digital-operation-sevice.jpg"),
  // DgtDigitalWorkforceService: require("../assets/images/dgt/digital-workforce-enablement-service.jpg"),
  // DgtDigitizationConsultingService: require("../assets/images/dgt/digitization-consulting-support-service.jpg"),
  // DgtOpBanner: require("../assets/images/dgt/dgt-op-banner.jpeg"),
  // ExpertiseOperateion: require("../assets/images/dgt/doi-meno-expertise.jpg"),
  // DgtOpWedo: require("../assets/images/dgt/dgt-op-wedo.png"),
  // DgtServiceCustExp: require("../assets/images/dgt/customer-experience-transform-service.jpg"),
  // DigitalTransformationBanner: require("../assets/images/dgt/digital-transformation-banner.png"),
  // Highly: require("../assets/images/dgt/highly.png"),
  // LeaderShip: require("../assets/images/dgt/leadership.png"),
  // Passionate: require("../assets/images/dgt/passionate.png"),
  // Regulation: require("../assets/images/dgt/regulation.png"),
  // SegmentItes: require("../assets/images/dgt/segment-ITES.png"),
  // SegmentManufacturing: require("../assets/images/dgt/segment-manufacturing.png"),
  // SegmentRetail: require("../assets/images/dgt/segment-retail.png"),
  // SkillEnhancement: require("../assets/images/dgt/skill-enhancement.png"),
  // StartupDc: require("../assets/images/dgt/startup-dc.jpeg"),
  // Technical: require("../assets/images/dgt/technical.png"),
  // WhatOperations: require("../assets/images/dgt/what-operations.png"),
  // WhatSupport: require("../assets/images/dgt/what-support.png"),
  // WorkForceBanner: require("../assets/images/dgt/workforce-banner.jpeg"),

  // AppServiceMainBanner: require("../assets/images/application-services/application-banner.png"),
  // WebAppDevBanner: require("../assets/images/application-services/webappdevelopment-banner.jpg"),
  // MobileAppDevBanner: require("../assets/images/application-services/mobile-appdevelopmetn-banner.jpg"),
  // MobileAppDevAbout: require("../assets/images/application-services/mobile-app-about.jpg"),
  // AppAboutMain: require("../assets/images/application-services/app-serve-inno.png"),
  // AppTransIck: require("../assets/images/application-services/app-trans-ick.png"),
  // ApplicationDsBanner: require("../assets/images/application-services/application-ds-banner.jpeg"),
  // ApplicationTransBanner: require("../assets/images/application-services/application-trans-banner..jpeg"),
  // ApplicationTransUd: require("../assets/images/application-services/application-trans-ud.png"),
  // ApsTransThinkImg1: require("../assets/images/application-services/aps-trans-think-img-1.png"),
  // ApsTransThinkImg2: require("../assets/images/application-services/aps-trans-think-img-2.png"),
  // ApsTransThinkImg3: require("../assets/images/application-services/aps-trans-think-img-3.png"),
  // DevopsBanner: require("../assets/images/application-services/devops-banner.png"),
  // IndustriesSmart: require("../assets/images/application-services/Industries-smart.png"),
  // IndustriesSmart2: require("../assets/images/application-services/Industries-smart2.png"),
  // IndustriesSmart3: require("../assets/images/application-services/Industries-smart3.png"),
  // IndustriesSmart4: require("../assets/images/application-services/Industries-smart4.png"),
  // IndustriesSmart6: require("../assets/images/application-services/Industries-smart6.png"),
  // LifecycleBanner: require("../assets/images/application-services/lifecycle-banner.jpeg"),
  // LifecycleUnder: require("../assets/images/application-services/lifecycle-under.png"),
  // LifeCycleWhatThink: require("../assets/images/application-services/lifecycle-what-think.png"),
  // QualityEngDo: require("../assets/images/application-services/quality-engineering-do.png"),
  // SuccessJourney: require("../assets/images/application-services/successful-journey.png"),
  // TechDrivenDevops: require("../assets/images/application-services/tech-driven-devops.png"),
  // WhatWedoBack: require("../assets/images/application-services/what-weDo-back.png"),
  // LifecycleThink1: require("../assets/images/application-services/lifecycle-think1.png"),
  // LifecycleThink2: require("../assets/images/application-services/lifecycle-think2.png"),
  // LifecycleThink3: require("../assets/images/application-services/lifecycle-think3.png"),
  // ManagedITBanner: require("../assets/images/manageItSupport/manage-it-banner.jpg"),
  // ManagedITAboutMain: require("../assets/images/manageItSupport/network-itsupport-banner.jpg"),
  // ManagedITApproachIcon1: require("../assets/images/manageItSupport/Group143.png"),
  // ManagedITApproachIcon2: require("../assets/images/manageItSupport/Subtract.png"),
  // ManagedITApproachIcon3: require("../assets/images/manageItSupport/Union.png"),
  // ManagedITApproachIcon4: require("../assets/images/manageItSupport/Group142.png"),
  // ManageItManage: require("../assets/images/manageItSupport/managedit-manage-banner.jpg"),
  // ManageItAboutUpd: require("../assets/images/manageItSupport/managed-it-support-about-upd.avif"),
  // ManageItBfsi: require("../assets/images/manageItSupport/managed-it-support-bfsi.jpg"),
  // ManageItHealthCare: require("../assets/images/manageItSupport/health-care-managed-it.avif"),
  // ManageItEcommerce: require("../assets/images/manageItSupport/ecommerce-mangd-it-industry.jpg"),
  // ManageItIndustry: require("../assets/images/manageItSupport/managed-it-industry.jpg"),
  // ManagedItSecurityBanner: require("../assets/images/manageItSupport/managed-banner.jpeg"),
  // ManageItSetCloud: require("../assets/images/manageItSupport/setcloud.png"),
  // ManageItMultiPerson: require("../assets/images/manageItSupport/multiperson.png"),
  // ManageItNetSetting: require("../assets/images/manageItSupport/netsettinng.png"),
  // ManageItSetConnety: require("../assets/images/manageItSupport/setconnety.png"),
  // ManageItSetLock: require("../assets/images/manageItSupport/setlock.png"),
  // ManageItSubstract: require("../assets/images/manageItSupport/Subtract.png"),
  // ManageItUnion: require("../assets/images/manageItSupport/Union.png"),
  // ManageItSetLaptop: require("../assets/images/manageItSupport/setlaptop.png"),
  // ManageItNetworkInfraService: require("../assets/images/manageItSupport/network-infra-service.jpg"),
  // ManageItBlogLatestOne: require("../assets/images/manageItSupport/blog-latest-one-managed-it.jpg"),

  // CyberSecurityBanner: require("../assets/images/CyberSecurity/cyber-security-banner.png"),
  // CyberSecurityAbout: require("../assets/images/CyberSecurity/cyber-about-img.png"),

  // CyberSecurityBfsi: require("../assets/images/CyberSecurity/bfsi-cyber-industry.jpg"),
  // CyberSecurityHealthCare: require("../assets/images/CyberSecurity/health-cyber-indutry.jpg"),
  // CyberSecurityItes: require("../assets/images/CyberSecurity/ites-cyber-industry1.jpg"),
  // CyberSecurityEcommerce: require("../assets/images/CyberSecurity/ecommer-cyber-industry.jpg"),
  // CyberSecurityServiceRiskAdvisory: require("../assets/images/CyberSecurity/it-industry-third.png"),
  // CyberSecurityServiceCloudInfraSecurity: require("../assets/images/CyberSecurity/infra-cyber-security.png"),
  // InnerCyberSecurityBanner: require("../assets/images/CyberSecurity/innerPageCyberBanner.jpg"),
  // InnerCyberSecuritySolutions: require("../assets/images/CyberSecurity/cyber-solutions-ext.png"),
  // InnerCyberSecurityRiskMangIcon1: require("../assets/images/CyberSecurity/Risk-Identification.png"),
  // InnerCyberSecurityRiskMangIcon2: require("../assets/images/CyberSecurity/Risk-Assessment.png"),
  // InnerCyberSecurityRiskMangIcon3: require("../assets/images/CyberSecurity/RiskControl.png"),
  // InnerCyberSecurityRiskMangIcon4: require("../assets/images/CyberSecurity/ReviewControls.png"),
  // CloudMigrationExperts: require("../assets/images/cloud/migration-cloud.jpg"),
  // CloudMigrationExpertsIcon1: require("../assets/images/cloud/proven-expertise-icon.png"),
  // CloudMigrationExpertsIcon2: require("../assets/images/cloud/migration-solution-icon.png"),
  // CloudMigrationExpertsIcon3: require("../assets/images/cloud/secure-cloud-icon.png"),
  // CloudMigrationExpertsIcon4: require("../assets/images/cloud/grow-business-icon.png"),
  // CloudMigrationExpertsIcon5: require("../assets/images/cloud/customer-support-icon.png"),
  // TalentHrBanner: require("../assets/images/hr-talent/hr-talent-banner-main.png"),
  // TalentHrAbout: require("../assets/images/hr-talent/hr-talent-about.jpg"),
  // InnerTalentHrBanner: require("../assets/images/hr-talent/inner-page-banner-talent.jpg"),
  // DgtExpBanner: require("../assets/images/dgt-experience/dgt-experience-banner.png"),
  // DgtExpAbout: require("../assets/images/dgt-experience/dgt-experience-about.png"),
  // DgtExpContentMarketingBanner: require("../assets/images/dgt-experience/content-marketing-dge-banner.jpg"),
  // DgePrize: require("../assets/images/dgt-experience/dge-prize.png"),
  // DgeRocket: require("../assets/images/dgt-experience/dge-rocket.png"),
  // DgeGraph: require("../assets/images/dgt-experience/dge-graph.png"),
  // DgeHumanChat: require("../assets/images/dgt-experience/dge-chat-human.png"),
  // DgeBuildings: require("../assets/images/dgt-experience/dge-buildings.png"),
  // DgeDrivenSecImg1: require("../assets/images/dgt-experience/dge-driven-sec-img1.png"),
  // DgeGraph2: require("../assets/images/dgt-experience/dge-graphs-2.png"),
  // HumanTeam: require("../assets/images/dgt-experience/human-team.png"),
  // LikeThumb: require("../assets/images/dgt-experience/like-thumb.png"),

  // ServiceIndustryManufacturing: require("../assets/images/CyberSecurity/Industries-smart4.png"),
  // ServiceIndustryPsus: require("../assets/images/application-services/Industries-paus.png"),
  // ServiceIndustryEducation: require("../assets/images/application-services/Industries-education.png"),
  // ManufacturingBanner: require("../assets/images/Manufacturing/manufacturing-banner-main.png"),

  // // indus ites
  // ItesDgtAbout: require("../assets/images/industry/ites/ites-dgt-about.png"),
  // ItesIndusAbout: require("../assets/images/industry/ites/ites-industry-abour-sec.png"),
  // ItesIndusBanner: require("../assets/images/industry/ites/Ites-Industry-banner-img.png"),

  // // indus bfsi

  // BfsiBannerNew: require("../assets/images/industry/bfsi/bfsi-banner-new.png"),
  // BpoBfsiBanner: require("../assets/images/industry/bfsi/bpo-bfsi-banner.jpeg"),
  // BuisnessSett: require("../assets/images/industry/bfsi/business-sett.png"),
  // LaptopBuisness: require("../assets/images/industry/bfsi/latop-business.png"),
  // MultiUserBus: require("../assets/images/industry/bfsi/multi-user-bus.png"),
  // MagnitBfsiBanner: require("../assets/images/industry/bfsi/mangit-bfsi-banner.jfif"),
  // AboutMagnitBfsi: require("../assets/images/industry/bfsi/about-magdit-bfsi.jfif"),
  // CyberBfsiBanner: require("../assets/images/industry/bfsi/cyber-security-bfsi-banner.jpeg"),
  // CloudBfsiBanner: require("../assets/images/industry/bfsi/cloud-bfsi-banner.png"),
  // HrtalentBfsiBanner: require("../assets/images/industry/bfsi/hrtalent=-bfsi-banner.png"),
  // TalentAboutBfsi: require("../assets/images/industry/bfsi/talent-about-bfsi.jpeg"),
  // TalentServiceBfsi: require("../assets/images/industry/bfsi/talent-service-bfsi.jpeg"),
  // RevolutionBfsi: require("../assets/images/industry/bfsi/revolutionizing-bfsi.jpeg"),

  // // indus retails
  // RetailBannerMainPage: require("../assets/images/industry/retails/retail-banner-main-page.jpg"),
  // RetailMainAbout: require("../assets/images/industry/retails/retail-main-about-img.png"),
  // RetailBpoAbout: require("../assets/images/industry/retails/retails-bpo-about-img.png"),
  // RetailSuccessStory: require("../assets/images/industry/retails/retail-success-stories-img.jpg"),

  // LogisticsAboutImg: require("../assets/images/industry/logistics/logistic-about-img.png"),
  // LogisticsMainBanner: require("../assets/images/industry/logistics/logistic-main-banner.jpg"),

  // EcomBanner: require("../assets/images/industry/ecommerce/ecommerce-indus-banner.jpg"),
  // MainEcomAbout: require("../assets/images/industry/ecommerce/main-ecommerce-about-img.png"),
  // ServiceBfsiInr: require("../assets/images/industry/ecommerce/service-bfsi-inr.jpeg"),
  // OnlineAssetsComp: require("../assets/images/industry/ecommerce/online-assets-comp.png"),

  // EducationBanner: require("../assets/images/industry/education/education-banner.jpg"),

  // HealthCareAbout: require("../assets/images/industry/health-care/health-appservice-about.jpg"),

  // // ceo new page
  // CeoNewImgBg: require("../assets/images/main-site/ceo-new-img-bg.png"),

  // //  rpo services

  // // --------------- technologies icons ---------------- //
  // AngularIcon: require("../assets/images/industry/it/angular-icon.png"),
  // CssIcon: require("../assets/images/industry/it/css-icon.png"),
  // HtmlIcon: require("../assets/images/industry/it/html-icon.png"),
  // NodeJSIcon: require("../assets/images/industry/it/nodejs.png"),
  // rubyIcon: require("../assets/images/industry/it/ruby.png"),
  // expressIcon: require("../assets/images/industry/it/express.png"),
  // flaskIcon: require("../assets/images/industry/it/flask.png"),
  // PhpIcon: require("../assets/images/industry/it/php.png"),
  // DjangoIcon: require("../assets/images/industry/it/django.png"),
  // SpringBootIcon: require("../assets/images/industry/it/spring-boot.png"),
  // AspNetIcon: require("../assets/images/industry/it/aspnet.png"),
  // PythonIcon: require("../assets/images/industry/it/python.png"),
  // MysqlIcon: require("../assets/images/industry/it/mysql.png"),
  // MongoDBIcon: require("../assets/images/industry/it/mongo_db.png"),
  // PostGresQl: require("../assets/images/industry/it/postgresql.png"),
  // SalesForceIcon: require("../assets/images/industry/it/salesforce.png"),
  // ShopifyIcon: require("../assets/images/industry/it/shppify.png"),
  // WordpressIcon: require("../assets/images/industry/it/wordpress.png"),
  // MagentoIcon: require("../assets/images/industry/it/magento.png"),
  // JavaIcon: require("../assets/images/industry/it/java.png"),
  // flutterIcon: require("../assets/images/home/flutter-icon.png"),
  // laravelIcon: require("../assets/images/home/laravel-icon.png"),
  // viejsIcon: require("../assets/images/home/veijs-icon.png"),
  // adsenseIcon: require("../assets/images/home/google-adsense.png"),
  // SwiftIcon: require("../assets/images/industry/it/swift.png"),
  // KotlinIcon: require("../assets/images/industry/it/kotlin.png"),
  // ObjectiveC: require("../assets/images/industry/it/objc.png"),
  // ReactIcon: require("../assets/images/industry/it/reactjs.png"),
  // BlockChain: require("../assets/images/industry/it/blockchain.png"),
  // Oracle: require("../assets/images/industry/it/oracle.png"),
  // EtherIcon: require("../assets/images/industry/it/ether.png"),
  // Web3Js: require("../assets/images/industry/it/web3js.png"),
  // Azure: require("../assets/images/industry/it/azure.png"),
  // AwsIcon: require("../assets/images/industry/it/aws.png"),
  // GCSIcon: require("../assets/images/industry/it/gcs.png"),

  // winelogoIcon: require("../assets/images/home/wine-logo.png"),
  // mapdirectionIcon: require("../assets/images/home/map_direction-logo.png"),
  // banklogoIcon: require("../assets/images/home/bank-logo.png"),
  // healthCareIcon: require("../assets/images/home/health-care-logo.png"),
  // retailIcon: require("../assets/images/home/shoping-logo.png"),
  // bookIcon: require("../assets/images/home/book-logo.png"),
  // socialMediaIcon: require("../assets/images/home/social-media-logo.png"),
  // travelIcon: require("../assets/images/home/global-business-emoji.png"),
  // realEstateIcon: require("../assets/images/home/home-emoji.png"),
  // eventsIcon: require("../assets/images/home/congrate-emoji.png"),
  // telecomIcon: require("../assets/images/home/goal-logo.png"),
  // businessIcon: require("../assets/images/home/hank-shake-logo.png"),
  // homemapoffices: require("../assets/images/home/home-map-offices.png"),

  // ManageItBfsiNew: require("../assets/images/manageItSupport/manage-it-bfsi-new.jpg"),
  // CyberIdeAccess: require("../assets/images/CyberSecurity/cyberIdeAccess.avif"),
  // CyberThreatDetection: require("../assets/images/CyberSecurity/cyber-threat-detection.avif"),
  // CyberRiskManage: require("../assets/images/CyberSecurity/cyber-risk-manage.jpg.png"),
  // CloudBfsi: require("../assets/images/cloud/cloud-bfsi.jpg"),
  // CloudHealth: require("../assets/images/cloud/cloud-health.jpg"),
  // CloudIt: require("../assets/images/cloud/cloud-it.jpg"),
  // CloudItes: require("../assets/images/cloud/cloud-ites.jpg"),
  // CloudEcommerce: require("../assets/images/cloud/cloud-ecommerce.jpg"),
  // CloudRetail: require("../assets/images/cloud/cloud-retail.jpeg"),
  // CloudManufacturing: require("../assets/images/cloud/cloud-manufacturing.jpg"),
  // CloudManageService: require("../assets/images/cloud/cloud-manage-service.jpg"),
  // CloudAppData: require("../assets/images/cloud/cloud-app-data.jpg"),
  // CloudDevepsSupport: require("../assets/images/cloud/cloud-devops-support.png"),
  // ApplicationServRetails: require("../assets/images/application-services/aps-retails.jpg"),
  // ApplicationServEcom: require("../assets/images/application-services/aps-ecpmmerce.avif"),
  // ApplicationServHealth: require("../assets/images/application-services/aps-health.avif"),
  // ApplicationServManufacturing: require("../assets/images/application-services/aps-manufacturing.avif"),
  // ApplicationServTeavel: require("../assets/images/application-services/aps-travel.jpg"),
  // ApplicationServEducation: require("../assets/images/application-services/aps-education.avif"),
  // ApplicationServBlog: require("../assets/images/application-services/aps-blog.jpg"),
  // HrAboutNew: require("../assets/images/hr-talent/hr-talent-about-new.jpg"),
  // HrBfsiNew: require("../assets/images/hr-talent/hr-talent-bfsi-new.jpg"),
  // HrHealthNew: require("../assets/images/hr-talent/hr-talent-health-new.jpg"),
  // HrEcomNew: require("../assets/images/hr-talent/hr-talent-ecommerce-new.jpg"),
  // HeTechnologyNew: require("../assets/images/hr-talent/hr-talent-technology.avif"),
  // HrTalentAqucious: require("../assets/images/hr-talent/hr-talent-acquacious.jpg"),
  // HrTalentRpo: require("../assets/images/hr-talent/hr-talent-rpo.jpg"),
  // HrTalentConsulting: require("../assets/images/hr-talent/hr-consulting.jpg"),
  // HrBlog: require("../assets/images/hr-talent/hr-blog.jpg"),
  // DgeTravelTourism: require("../assets/images/dgt-experience/dge-travel-and-tourism.jpg"),
  // DgeHealthCare: require("../assets/images/dgt-experience/dge-health-care.jpg"),
  // DgeEcommerce: require("../assets/images/dgt-experience/dge-ecommerce.jpg"),
  // DgeItes: require("../assets/images/dgt-experience/dge-ites.jpg"),
  // DgeContentMarketing: require("../assets/images/dgt-experience/dge-content-marketing.jpg"),
  // DgeUiDesign: require("../assets/images/dgt-experience/ui-and-design.jpg"),
  // DgeMultiCulture: require("../assets/images/dgt-experience/dge-multi-culture.jpg"),
  // DgeBlog: require("../assets/images/dgt-experience/dge-blog.jpg"),
  // ManageItManufacturing: require("../assets/images/manageItSupport/manage-it-manufaturing.jpg"),
  // CyberSecurityManufacturing: require("../assets/images/CyberSecurity/cyber-security-manufacturing.jpg"),
  // CloudItesNew: require("../assets/images/cloud/cloud-ites-new.jpg"),
  // CloudManufacturingNew: require("../assets/images/cloud/cloud-manaufacturing-new.png"),
  // ApsServicesAboutNew: require("../assets/images/application-services/aps-services-about-new.jpg"),
  // ApsHealthNew: require("../assets/images/application-services/aps-health-new.jpg"),
  // HrTalentContigentStaffing: require("../assets/images/hr-talent/hr-talent-contegent-staffing.jpg"),
  // DgeVirtual: require("../assets/images/dgt-experience/dge-what-we-do-virtual.jpg"),
  // DgeWebsiteLocalization: require("../assets/images/dgt-experience/dge-website-localization.jpg"),
  // BfsiAboutNew: require("../assets/images/industry/bfsi/bfsi-about-new.jpg"),
  // BfsiBpo: require("../assets/images/industry/bfsi/bfsi-bpo.jpg"),
  // BfsiManageIt: require("../assets/images/industry/bfsi/bfsi-manageit.avif"),
  // BfsiCyber: require("../assets/images/industry/bfsi/bfsi-cyber.avif"),
  // BfsiCloud: require("../assets/images/industry/bfsi/bfsi-cloud.jpg"),
  // BfsiHrTalent: require("../assets/images/industry/bfsi/bfsi-hr-talent.avif"),
  // ItAbout: require("../assets/images/industry/it/it-about.jpg"),
  // ItesBanner: require("../assets/images/industry/ites/ites-banner.jpg"),
  // ItesAbout: require("../assets/images/industry/ites/ites-about.jpg"),
  // ItesDigitalTransformation: require("../assets/images/industry/ites/ites-digital-info.jpg"),
  // ItesManageIt: require("../assets/images/industry/ites/ites-manage-it.avif"),
  // ItesCyber: require("../assets/images/industry/ites/ites-cyber.jpg"),
  // ItesCloud: require("../assets/images/industry/ites/ites-cloud.jpg"),
  // ItesDigitalExp: require("../assets/images/industry/ites/ites-digital-exp.avif"),
  // RetailsAboutNew: require("../assets/images/industry/retails/retail-about-new.jpg"),
  // RetailBpo: require("../assets/images/industry/retails/retail-bpo.avif"),
  // HomeScroll: require("../assets/images/main-site/home-scroll.jpg"),
  // DgeVirtualWhat: require("../assets/images/dgt-experience/dge-virtual.jpg"),
  // TestimonialAvatar: require("../assets/images/main-site/testimonial-avatar.webp"),

  // RetailDigitalTransformation: require("../assets/images/industry/retails/retail-digital-transformation.jpg"),
  // RetailApsService: require("../assets/images/industry/retails/retail-aps-service.jpg"),
  // RetailItSupport: require("../assets/images/industry/retails/retail-it-support.jpg"),
  // RetailCyber: require("../assets/images/industry/retails/retail-cyber.jpg"),
  // RetailCloud: require("../assets/images/industry/retails/retail-cloud.jpg"),
  // EducationBannerNew: require("../assets/images/industry/education/education-banner-new.jpg"),
  // EducationAbout: require("../assets/images/industry/education/education-about.jpg"),
  // EducationApsService: require("../assets/images/industry/education/education-aps-service.jpg"),
  // LogisticBannerNew: require("../assets/images/industry/logistics/logistic-banner-new.jpg"),
  // LogisticAbout: require("../assets/images/industry/logistics/logistic-about.jpg"),
  // LogisticBpo: require("../assets/images/industry/logistics/logistic-bpo.jpg"),
  // TechnologyBanner: require("../assets/images/industry/technology/technologyBanner.jpg"),
  // TechnologyAbout: require("../assets/images/industry/technology/technology-about.jpg"),
  // TechnologyBpo: require("../assets/images/industry/technology/technology-bpo.jpg"),
  // TechnologyHr: require("../assets/images/industry/technology/technology-hr.jpg"),
  // HealthCareAboutNew: require("../assets/images/industry/health-care/health-care-about.jpg"),
  // HealthCareApsService: require("../assets/images/industry/health-care/health-care-aps-service.jpg"),
  // CloudBannerNew: require("../assets/images/cloud/cloud-banner-new.jpg"),
  // HealthCareBpo: require("../assets/images/industry/health-care/health-care-bpo.jpg"),
  // HealthCareManageIt: require("../assets/images/industry/health-care/health-care-manage-it.jpg"),
  // HealthCareCyber: require("../assets/images/industry/health-care/health-care-cyber.jpg"),
  // HealthCareHr: require("../assets/images/industry/health-care/health-care-hr.jpg"),
  // HealthCareCloud: require("../assets/images/industry/health-care/health-care-cloud.jpg"),
  // HealthCareDge: require("../assets/images/industry/health-care/health-care-dge.jpg"),
  // EcomAbout: require("../assets/images/industry/ecommerce/ecom-about.jpg"),
  // EcomBPO: require("../assets/images/industry/ecommerce/ecom-bpo.jpg"),
  // EcomApsService: require("../assets/images/industry/ecommerce/ecom-aps-service.jpg"),
  // EcomManageIt: require("../assets/images/industry/ecommerce/ecom-manage-it.jpg"),
  // EcomCyber: require("../assets/images/industry/ecommerce/ecom-cyber.jpg"),
  // EcomCloud: require("../assets/images/industry/ecommerce/ecom-cloud.jpg"),
  // EcomHr: require("../assets/images/industry/ecommerce/ecom-hr.jpg"),
  // EcomDge: require("../assets/images/industry/ecommerce/ecom-dge.jpg"),
  // ManufacturingAbout: require("../assets/images/Manufacturing/manufacturing-about.png.jpg"),
  // ManufacturingDgt: require("../assets/images/Manufacturing/manufacturing-dgt.png.jpg"),
  // ManufacturingApsService: require("../assets/images/Manufacturing/manufacturing-aps-service.png.jpg"),
  // ManufacturingManageIt: require("../assets/images/Manufacturing/manufacturing-manage-it.png.jpg"),
  // ManufacturingCyber: require("../assets/images/Manufacturing/manufacturing-cyber.png.jpg"),
  // ManufacturingCloud: require("../assets/images/Manufacturing/manufacturing-cloud.jpg"),
  // TravelBanner: require("../assets/images/industry/travel/travell-banner.jpg"),
  // TravelBpo: require("../assets/images/industry/travel/travel-bpo.jpg"),
  // TravelAps: require("../assets/images/industry/travel/travell-aps.jpg"),
  // TravelDigital: require("../assets/images/industry/travel/travel-digital.jpg"),
  // BpoSalesServices1: require("../assets/images/bpo/bposales_services1.jpg"),
  // BpoSalesServices2: require("../assets/images/bpo/bposales_services2.jpg"),
  // BpoSalesServices3: require("../assets/images/bpo/bposales_services3.jpg"),
  // BpoSalesServices4: require("../assets/images/bpo/bposales_services4.jpg"),
  // BpoSalesServices5: require("../assets/images/bpo/bposales_services5.jpg"),
  // BpoSalesServices6: require("../assets/images/bpo/bposales_services6.jpg"),
  // BpoSalesServices7: require("../assets/images/bpo/bposales_services7.jpg"),
  // BpoSalesServices8: require("../assets/images/bpo/bposales_services8.jpg"),
  // BpoStateOfSales: require("../assets/images/bpo/bpo_state_of_sales.jpg"),
  // BpoSalesBlog: require("../assets/images/bpo/bpo_sales_blog.jpg"),
  // BpoInsurenceBanner: require("../assets/images/bpo/bpo_insurence_banner.jpg"),
  // BpoInsurenceAbout: require("..//assets/images/bpo/bpo_insurence_about.jpg"),
  // BpoInsurenceServices1: require("../assets/images/bpo/bpo_insurence_services1.jpg"),
  // BpoInsurenceServices2: require("../assets/images/bpo/bpo_insurence_services2.jpg"),
  // BpoInsurenceServices3: require("../assets/images/bpo/bpo_insurence_services3.jpg"),
  // BpoInsurenceServices4: require("../assets/images/bpo/bpo_insurence_services4.jpg"),

  // BpoInsurenceJourney1: require("../assets/images/bpo/bpo_insurence_journey1.jpg"),
  // BpoInsurenceJourney2: require("../assets/images/bpo/bpo_insurence_journey2.jpg"),
  // BpoInsurenceJourney3: require("../assets/images/bpo/bpo_insurence_journey3.jpg"),
  // BpoInsurenceJourney4: require("../assets/images/bpo/bpo_insurence_journey4.jpg"),
  // BpoInsurenceDayAfter: require("../assets/images/bpo/bpo_insurence_day_after.jpg"),
  // BpoInsurenceDerisking: require("../assets/images/bpo/bpo_insurence_derisking.jpg"),
  // BpoInsurenceDataMoni: require("../assets/images/bpo/bpo_insurence_data_moni.jpg"),
  // BpoInsurenceAgile: require("../assets/images/bpo/bpo_insurence_agile.jpg"),
  // BpoInsurenceBlog: require("../assets/images/bpo/bpo_insurence_blog.jpg"),
  // BpoFinanceBanner: require("../assets/images/bpo/bpo_finance_banner.jpg"),
  // BpoFinanceAbout: require("../assets/images/bpo/bpo_finance_about.jpg"),
  // BpoFinanceCash: require("../assets/images/bpo/bpo_finance_cash.jpg"),
  // BpoFinanceRegular: require("../assets/images/bpo/bpo_finance_regular.jpg"),
  // BpoFinanceBlockChain: require("../assets/images/bpo/bpo_finance_blockchain.jpg"),
  // BpoFinanceAi: require("../assets/images/bpo/bpo_finance_ai.jpg"),
  // BpoFinanceFintech: require("../assets/images/bpo/bpo_finance_fintech.jpg"),
  // BpoFinanceDgt: require("../assets/images/bpo/bpo_finance_dgt.jpg"),
  // BpoFinanceBlog: require("../assets/images/bpo/bpo_finance_blog.jpg"),
  // BpoHealthBanner: require("../assets/images/bpo/bpo_health_banner.jpg"),
  // BpoHealthExpertise: require("../assets/images/bpo/bpo_health_expertise.jpg"),
  // BpoHealthBlog: require("../assets/images/bpo/bpo_health_blog.jpg"),
  // BpoDoiBanner: require("../assets/images/bpo/bpo_doi_banner.jpg"),
  // BpoDoiData: require("../assets/images/bpo/bpo_doi_data.jpg"),
  // BpoDoiEnhance: require("../assets/images/bpo/bpo_doi_enhance.jpg"),
  // BpoDoiPredictive: require("../assets/images/bpo/bpo_doi_predictive.jpg"),
  // BpoDoiCleasimg: require("../assets/images/bpo/bpo_doi_cleasing.jpg"),
  // BpoDoiDataAnalysis: require("../assets/images/bpo/bpo_doi_data_analysis.jpg"),
  // BpoHealthService1: require("../assets/images/bpo/bpo-health-service1.jpg"),
  // BpoHealthService2: require("../assets/images/bpo/bpo-health-service2.jpg"),
  // BpoHealthService3: require("../assets/images/bpo/bpo-health-service3.jpg"),
  // BpoHealthService4: require("../assets/images/bpo/bpo-health-service4.jpg"),
  // BpoDoiServices3: require("../assets/images/bpo/bpo_doi_services3.jpg"),
  // BpoDoiServices4: require("../assets/images/bpo/bpo_doi_services4.jpg"),
  // BpoDoiCase1: require("../assets/images/bpo/bpo_doi_case1.jpg"),
  // BpoDoiCase2: require("../assets/images/bpo/bpo_doi_case2.jpg"),
  // BpoDoiCase3: require("../assets/images/bpo/bpo_doi_case3.jpg"),
  // BpoLpoBanner: require("../assets/images/bpo/bpo_lpo_banner.jpg"),
  // BpoLpoAbout: require("../assets/images/bpo/bpo_lpo_about.jpg"),
  // BpoLpoServices1: require("../assets/images/bpo/bpo_lpo_services1.jpg"),
  // BpoLpoServices2: require("../assets/images/bpo/bpo_lpo_services2.jpg"),
  // BpoLpoServices3: require("../assets/images/bpo/bpo_lpo_services3.jpg"),
  // BpoLpoServices4: require("../assets/images/bpo/bpo_lpo_services4.jpg"),
  // BpoLpoOperation: require("../assets/images/bpo/bpo_lpo_operation.jpg"),

  // DgtCustomerBanner: require("../assets/images/dgt/dgt-customer-banner.jpg"),
  // DgtCustomerAbout: require("../assets/images/dgt/dgt-customer-about.jpg"),
  // DgtCustomerServices1: require("../assets/images/dgt/dgt-customer-services1.jpg"),
  // DgtCustomerServices2: require("../assets/images/dgt/dgt-customer-services2.jpg"),
  // DgtCustomerServices3: require("../assets/images/dgt/dgt-customer-services3.jpg"),
  // DgtCustomerServices4: require("../assets/images/dgt/dgt-customer-services4.jpg"),
  // DgtCustomerServices5: require("../assets/images/dgt/dgt-customer-services5.jpg"),
  // DgtCustomerServices6: require("../assets/images/dgt/dgt-customer-services6.jpg"),
  // DgtCustomerServices7: require("../assets/images/dgt/dgt-customer-services7.jpg"),
  // DgtCustomerServices8: require("../assets/images/dgt/dgt-customer-services8.jpg"),
  // DgtCustomerServices9: require("../assets/images/dgt/dgt-customer-services9.jpg"),
  // DgtCustomerNewEra: require("../assets/images/dgt/dgt-customer-new-era.jpg"),
  // DgtCustomerExp1: require("../assets/images/dgt/dgt-customer-exp1.jpg"),
  // DgtCustomerExp2: require("../assets/images/dgt/dgt-customer-exp2.jpg"),
  // DgtCustomerExp3: require("../assets/images/dgt/dgt-customer-exp3.jpg"),
  // DgtCustomerBlog: require("../assets/images/dgt/dgt-customer-blog.jpg"),
  // DgtOperationBanner: require("../assets/images/dgt/dgt-operation-banner.jpg"),
  // DgtOperationServices1: require("../assets/images/dgt/dgt-operation-services1.jpg"),
  // DgtOperationServices2: require("../assets/images/dgt/dgt-operation-services2.jpg"),
  // DgtOperationServices3: require("../assets/images/dgt/dgt-operation-services3.jpg"),
  // DgtOperationServices4: require("../assets/images/dgt/dgt-operation-services4.jpg"),
  // DgtOperationServices5: require("../assets/images/dgt/dgt-operation-services5.jpg"),
  // DgtOperationServices6: require("../assets/images/dgt/dgt-operation-services6.jpg"),
  // DgtOperationBlog: require("../assets/images/dgt/dgt-operation-blog.jpg"),
  // DgtWorkforceBanner: require("../assets/images/dgt/dgt-workforce-banner.jpg"),
  // DgtWorkforceAbout: require("../assets/images/dgt/dgt-workforce-about.jpg"),
  // DgtWorkforceServices1: require("../assets/images/dgt/dgt-workforce-services1.jpg"),
  // DgtWorkforceServices2: require("../assets/images/dgt/dgt-workforce-services2.jpg"),
  // DgtWorkforceServices3: require("../assets/images/dgt/dgt-workforce-services3.jpg"),
  // DgtWorkforceServices4: require("../assets/images/dgt/dgt-workforce-services4.jpg"),
  // DgtWorkforceServices5: require("../assets/images/dgt/dgt-workforce-services5.jpg"),
  // DgtWorkforceServices6: require("../assets/images/dgt/dgt-workforce-services6.jpg"),
  // DgtWorkforceServices7: require("../assets/images/dgt/dgt-workforce-services7.jpg"),
  // DgtWorkforcePioneer: require("../assets/images/dgt/dgt-workforce-pioneer.jpg"),
  // DgtWorkforce1: require("../assets/images/dgt/dgt-workforce1.jpg"),
  // DgtWorkforce2: require("../assets/images/dgt/dgt-workforce2.jpg"),
  // DgtWorkforce3: require("../assets/images/dgt/dgt-workforce3.jpg"),
  // DgtWorkforceBlog: require("../assets/images/dgt/dgt-workforce-blog.jpg"),
  // DgtConsultingBanner: require("../assets/images/dgt/dgt-consulting-banner.jpg"),
  // DgtConsultingAbout: require("../assets/images/dgt/dgt-consulting-about.jpg"),
  // DgtConsultingServices1: require("../assets/images/dgt/dgt-consulting-services1.jpg"),
  // DgtConsultingServices2: require("../assets/images/dgt/dgt-consulting-services2.jpg"),
  // DgtConsultingServices3: require("../assets/images/dgt/dgt-consulting-services3.jpg"),
  // DgtConsultingServices4: require("../assets/images/dgt/dgt-consulting-services4.jpg"),
  // DgtConsultingServices5: require("../assets/images/dgt/dgt-consulting-services5.jpg"),
  // DgtConsultingServices6: require("../assets/images/dgt/dgt-consulting-services6.jpg"),
  // DgtConsultingCreate1: require("../assets/images/dgt/dgt-consulting-create1.jpg"),
  // DgtConsultingCreate2: require("../assets/images/dgt/dgt-consulting-create2.jpg"),
  // DgtConsultingExp1: require("../assets/images/dgt/dgt-consulting-exp1.jpg"),
  // DgtConsultingExp2: require("../assets/images/dgt/dgt-consulting-exp2.jpg"),
  // DgtConsultingExp3: require("../assets/images/dgt/dgt-consulting-exp3.jpg"),
  // DgtConsultingBlog: require("../assets/images/dgt/dgt-consulting-blog.jpg"),
  // ItInfraBanner: require("../assets/images/industry/it/it-infra-banner.jpg"),
  // ItInfraAbout: require("../assets/images/industry/it/it-infra-about.jpg"),
  // ItInfraServices1: require("../assets/images/industry/it/it-infra-services1.jpg"),
  // ItInfraServices2: require("../assets/images/industry/it/it-infra-services2.jpg"),
  // ItInfraServices3: require("../assets/images/industry/it/it-infra-services3.jpg"),
  // ItInfraServices4: require("../assets/images/industry/it/it-infra-services4.jpg"),
  // ItInfraServices5: require("../assets/images/industry/it/it-infra-services5.jpg"),
  // ItInfraServices6: require("../assets/images/industry/it/it-infra-services6.jpg"),
  // ItInfraMaximize: require("../assets/images/industry/it/it-infra-maximize.jpg"),
  // ItInfraExp1: require("../assets/images/industry/it/it-infra-exp1.jpg"),
  // ItInfraExp2: require("../assets/images/industry/it/it-infra-exp2.jpg"),
  // ItInfraBlog: require("../assets/images/industry/it/it-infra-blog.jpg"),
  // ItSecurityBanner: require("../assets/images/industry/it/it-security-banner.jpg"),
  // ItSecurityServices1: require("../assets/images/industry/it/it-security-threat.jpg"),
  // ItSecurityServices2: require("../assets/images/industry/it/it-security-services2.jpg"),
  // ItSecurityServices3: require("../assets/images/industry/it/it-security-services3.jpg"),
  // ItSecurityServices4: require("../assets/images/industry/it/it-security-services4.jpg"),
  // ItSecurityServices5: require("../assets/images/industry/it/it-security-services5.jpg"),
  // ItSecurityServices6: require("../assets/images/industry/it/it-security-services6.jpg"),
  // ItSecurityCutting: require("../assets/images/industry/it/it-security-cutting.jpg"),
  // ItSecurityExp1: require("../assets/images/industry/it/it-security-exp1.jpg"),
  // ItSecurityWxp2: require("../assets/images/industry/it/it-security-exp2.jpg"),
  // ItManageServices1: require("../assets/images/manageItSupport/it-manage-services1.jpg"),
  // ItManageServices2: require("../assets/images/manageItSupport/it-manage-services2.jpg"),
  // ItManageServices3: require("../assets/images/manageItSupport/it-manage-services3.jpg"),
  // ItManageServices4: require("../assets/images/manageItSupport/it-manage-services4.jpg"),
  // ItManageServices5: require("../assets/images/manageItSupport/it-manage-services5.jpg"),
  // ItManageServices6: require("../assets/images/manageItSupport/it-manage-services6.jpg"),
  // ItManageWhatThink: require("../assets/images/manageItSupport/it-manage-what-think.jpg"),
  // ItManageWhatThink1: require("../assets/images/manageItSupport/it-manage-think1.jpg"),
  // CLoudAppDataBanner: require("../assets/images/cloud/cloud-app-data-banner.jpg"),
  // CLoudAppDataEnd: require("../assets/images/cloud/cloud-app-data-end.jpg"),
  // CLoudAppDataServices1: require("../assets/images/cloud/cloud-app-data-services1.jpg"),
  // CLoudAppDataServices2: require("../assets/images/cloud/cloud-app-data-services2.jpg"),
  // CLoudAppDataServices3: require("../assets/images/cloud/cloud-app-data-services3.jpg"),
  // CLoudAppDataServices4: require("../assets/images/cloud/cloud-app-data-services4.jpg"),
  // CLoudAppDataMigration: require("../assets/images/cloud/cloud-app-data-migration.jpg"),
  // CLoudAppDataDynamic: require("../assets/images/cloud/cloud-app-data-dynamic.jpg"),
  // CLoudAppDataThink1: require("../assets/images/cloud/cloud-app-data-think1.jpg"),
  // CLoudAppDataThink2: require("../assets/images/cloud/cloud-app-data-think2.jpg"),
  // CLoudAppDataBlog: require("../assets/images/cloud/cloud-app-data-blog.jpg"),
  // ManageCloudBanner: require("../assets/images/cloud/manage-cloud-banner.jpg"),
  // ManageCloudAbout: require("../assets/images/cloud/manage-cloud-about.jpg"),
  // ManageCloudServices1: require("../assets/images/cloud/manage-cloud-services1.jpg"),
  // ManageCloudServices2: require("../assets/images/cloud/manage-cloud-services2.jpg"),
  // ManageCloudServices3: require("../assets/images/cloud/manage-cloud-services3.jpg"),
  // ManageCloudServices4: require("../assets/images/cloud/manage-cloud-services4.jpg"),
  // ManageCloudThink1: require("../assets/images/cloud/manage-cloud-think1.jpg"),
  // ManageCloudThink2: require("../assets/images/cloud/manage-cloud-think2.jpg"),
  // ManageCloudThink3: require("../assets/images/cloud/manage-cloud-think3.jpg"),
  // ManageCloudThink4: require("../assets/images/cloud/manage-cloud-think4.jpg"),
  // ManageCloudBlog: require("../assets/images/cloud/manage-cloud-blog.jpg"),
  // SoftwareStrategyBanner: require("../assets/images/application-services/software-strategy-banner.jpg"),
  // SoftwareStrategyAbout: require("../assets/images/application-services/software-strategy-about.jpg"),
  // SoftwareStrategyServices1: require("../assets/images/application-services/software-strategy-services1.jpg"),
  // SoftwareStrategyServices2: require("../assets/images/application-services/software-strategy-services2.jpg"),
  // SoftwareStrategyServices3: require("../assets/images/application-services/software-strategy-services3.jpg"),
  // SoftwareStrategyServices4: require("../assets/images/application-services/software-strategy-services4.jpg"),
  // SoftwareStrategyServices5: require("../assets/images/application-services/software-strategy-services5.jpg"),
  // SoftwareStrategyServices6: require("../assets/images/application-services/software-strategy-services6.jpg"),
  // SoftwareStrategyServices7: require("../assets/images/application-services/software-strategy-services7.jpg"),
  // SoftwareStrategyServices8: require("../assets/images/application-services/software-strategy-services8.jpg"),
  // SoftwareStrategyServices9: require("../assets/images/application-services/software-strategy-services9.jpg"),
  // SoftwareStrategyServices10: require("../assets/images/application-services/software-strategy-services10.jpg"),
  // SoftwareStrategyAbout2: require("../assets/images/application-services/software-strategy-about2.jpg"),
  // SoftwareWebBanner: require("../assets/images/application-services/software-web-banner.jpg"),
  // SoftwareWebAbout: require("../assets/images/application-services/software-web-about.jpg"),
  // SoftwareWebServices1: require("../assets/images/application-services/software-web-services1.jpg"),
  // SoftwareWebServices2: require("../assets/images/application-services/software-web-services2.jpg"),
  // SoftwareWebServices3: require("../assets/images/application-services/software-web-services3.jpg"),
  // SoftwareWebServices4: require("../assets/images/application-services/software-web-services4.jpg"),
  // SoftwareWebServices5: require("../assets/images/application-services/software-web-services5.jpg"),
  // SoftwareWebServices6: require("../assets/images/application-services/software-web-services6.jpg"),
  // SoftwareWebBlog: require("../assets/images/application-services/software-web-blog.jpg"),
  // SoftwareMobileBanner: require("../assets/images/application-services/software-mobile-banner.png"),
  // SoftwareMobileAbout: require("../assets/images/application-services/software-mobile-about.jpg"),
  // SoftwareMobileServices1: require("../assets/images/application-services/software-mobile-services1.jpg"),
  // SoftwareMobileServices2: require("../assets/images/application-services/software-mobile-services3.jpg"),
  // SoftwareMobileServices3: require("../assets/images/application-services/software-mobile-services3.jpg"),
  // SoftwareMobileServices4: require("../assets/images/application-services/software-mobile-services4.jpg"),
  // SoftwareMobileServices5: require("../assets/images/application-services/software-mobile-services5.jpg"),
  // SoftwareMobileServices6: require("../assets/images/application-services/software-mobile-services6.jpg"),
  // SoftwareMobileServices7: require("../assets/images/application-services/software-mobile-services7.jpg"),
  // SoftwareMobileServices8: require("../assets/images/application-services/software-mobile-services8.jpg"),
  // SoftwareMobileBlog: require("../assets/images/application-services/software-mobile-blog.jpg"),
  // SoftwareApplicationBanner: require("../assets/images/application-services/software-application-banner.jpg"),
  // SoftwareApplicationAbout: require("../assets/images/application-services/software-application-about.jpg"),
  // SoftwareApplicationServices1: require("../assets/images/application-services/software-application-services1.jpg"),
  // SoftwareApplicationServices2: require("../assets/images/application-services/software-application-services2.jpg"),
  // SoftwareApplicationServices3: require("../assets/images/application-services/software-application-services3.jpg"),
  // SoftwareApplicationBlog: require("../assets/images/application-services/software-application-blog.jpg"),
  // SoftwareQualityServices1: require("../assets/images/application-services/software-quality-services1.jpg"),
  // SoftwareQualityServices2: require("../assets/images/application-services/software-quality-services2.jpg"),
  // SoftwareQualityServices3: require("../assets/images/application-services/software-quality-services3.jpg"),
  // SoftwareQualityServices4: require("../assets/images/application-services/software-quality-services4.jpg"),
  // SoftwareQualityServices5: require("../assets/images/application-services/software-quality-services5.jpg"),
  // SoftwareQualityServices6: require("../assets/images/application-services/software-quality-services6.jpg"),
  // SoftwareQualityServices7: require("../assets/images/application-services/software-quality-services7.jpg"),
  // SoftwareQualityServices8: require("../assets/images/application-services/software-quality-services8.jpg"),
  // SoftwareQualityServices9: require("../assets/images/application-services/software-quality-services9.jpg"),
  // SoftwareQualityAbout: require("../assets/images/application-services/software-quality-about.jpg"),
  // SoftwareQualityBlog: require("../assets/images/application-services/software-quality-blog.jpg"),
  // SoftwareDevopsBanner: require("../assets/images/application-services/software-devops-banner.jpg"),
  // SoftwareDevopsAbout: require("../assets/images/application-services/software-devops-about.jpg"),
  // SoftwareDevopsServices1: require("../assets/images/application-services/software-devops-services1.jpg"),
  // SoftwareDevopsServices2: require("../assets/images/application-services/software-devops-services2.jpg"),
  // SoftwareDevopsServices3: require("../assets/images/application-services/software-devops-services3.jpg"),
  // SoftwareDevopsServices4: require("../assets/images/application-services/software-devops-services4.jpg"),
  // SoftwareDevopsBlog: require("../assets/images/application-services/software-devops-blog.jpg"),
  // SoftwareLifecycleServices1: require("../assets/images/application-services/software-lifecycle-services1.jpg"),
  // SoftwareLifecycleServices2: require("../assets/images/application-services/software-lifecycle-services2.jpg"),
  // SoftwareLifecycleServices3: require("../assets/images/application-services/software-lifecycle-services3.jpg"),
  // SoftwareLifecycleServices4: require("../assets/images/application-services/software-lifecycle-services4.jpg"),
  // SoftwareLifecycleChallange: require("../assets/images/application-services/software-lifecycle-challange.jpg"),
  // SoftwareLifecycleBlog: require("../assets/images/application-services/software-lifecycle-blog.jpg"),
  // HrConsultingBanner: require("../assets/images/hr-talent/hr-consulting-banner.jpg"),
  // HrConsultingServices1: require("../assets/images/hr-talent/hr-consulting-services1.jpg"),
  // HrConsultingServices2: require("../assets/images/hr-talent/hr-consulting-services2.jpg"),
  // HrConsultingServices3: require("../assets/images/hr-talent/hr-consulting-services3.jpg"),
  // HrConsultingServices4: require("../assets/images/hr-talent/hr-consulting-services4.jpg"),
  // HrConsultingServices5: require("../assets/images/hr-talent/hr-consulting-services5.jpg"),
  // HrConsultingServices6: require("../assets/images/hr-talent/hr-consulting-services6.jpg"),
  // HrConsultingServices7: require("../assets/images/hr-talent/hr-consulting-services7.jpg"),
  // HrConsultingServices8: require("../assets/images/hr-talent/hr-consulting-services8.jpg"),
  // HrConsultingResources: require("../assets/images/hr-talent/hr-consulting-resources.jpg"),
  // HrConsultingAbout: require("../assets/images/hr-talent/hr-consulting-about.jpg"),
  // HrConsultingBlog: require("../assets/images/hr-talent/hr-consulting-blog.jpg"),
  // HrProcessBanner: require("../assets/images/hr-talent/hr-process-banner.jpg"),
  // HomeTestimonial1: require("../assets/images/home/home-testimonials1.png"),
  // HomeTestimonial2: require("../assets/images/home/home-testimonials2.png"),
  // HrProcessAbout: require("../assets/images/hr-talent/hr-process-about.jpg"),
  // HrProcessServices1: require("../assets/images/hr-talent/hr-process-services1.jpg"),
  // HrProcessServices2: require("../assets/images/hr-talent/hr-process-services2.jpg"),
  // HrProcessServices3: require("../assets/images/hr-talent/hr-process-services3.jpg"),
  // HrProcessServices4: require("../assets/images/hr-talent/hr-process-services4.jpg"),
  // HrProcessServices5: require("../assets/images/hr-talent/hr-process-services5.jpg"),
  // HrProcessServices6: require("../assets/images/hr-talent/hr-process-services6.jpg"),
  // HrProcessTalant: require("../assets/images/hr-talent/hr-process-talant.jpg"),
  // HrProcessBlog: require("../assets/images/hr-talent/hr-process-blog.jpg"),
  // HrStaffingBanner: require("../assets/images/hr-talent/hr-staffing-banner.jpg"),
  // HrStaffingAbout: require("../assets/images/hr-talent/hr-staffing-about.jpg"),
  // HrStaffingServices1: require("../assets/images/hr-talent/hr-staffing-services1.jpg"),
  // HrStaffingServices2: require("../assets/images/hr-talent/hr-staffing-services2.jpg"),
  // HrStaffingServices3: require("../assets/images/hr-talent/hr-staffing-services3.jpg"),
  // HrStaffingServices4: require("../assets/images/hr-talent/hr-staffing-services4.jpg"),
  // HrStaffingServices5: require("../assets/images/hr-talent/hr-staffing-services5.jpg"),
  // HrStaffingServices6: require("../assets/images/hr-talent/hr-staffing-services6.jpg"),
  // HrStaffingBuilding: require("../assets/images/hr-talent/hr-staffing-building.jpg"),
  // HrStaffingEmpower: require("../assets/images/hr-talent/hr-staffing-empower.jpg"),
  // HrStaffingBlog: require("../assets/images/hr-talent/hr-staffing-blog.jpg"),
  // DgeContentBanner: require("../assets/images/dgt-experience/dge-content-banner.jpg"),
  // DgeContentAbout: require("../assets/images/dgt-experience/dge-content-about.jpg"),
  // DgeContentAbout2: require("../assets/images/dgt-experience/dge-content-about2.jpg"),
  // DgeContentServices1: require("../assets/images/dgt-experience/dge-content-services1.jpg"),
  // DgeContentServices2: require("../assets/images/dgt-experience/dge-content-services2.jpg"),
  // DgeContentServices3: require("../assets/images/dgt-experience/dge-content-services3.jpg"),
  // DgeContentServices4: require("../assets/images/dgt-experience/dge-content-services4.jpg"),
  // DgeContentServices5: require("../assets/images/dgt-experience/dge-content-services5.jpg"),
  // DgeContentServices6: require("../assets/images/dgt-experience/dge-content-services6.jpg"),
  // DgeContentServices7: require("../assets/images/dgt-experience/dge-content-services7.jpg"),
  // DgeContentServices8: require("../assets/images/dgt-experience/dge-content-services8.jpg"),
  // DgeContentServices9: require("../assets/images/dgt-experience/dge-content-services9.jpg"),
  // DgeContentServices10: require("../assets/images/dgt-experience/dge-content-services10.jpg"),
  // DgeContentResult1: require("../assets/images/dgt-experience/dge-content-result1.jpg"),
  // DgeContentResult2: require("../assets/images/dgt-experience/dge-content-result2.jpg"),
  // DgeContentResult3: require("../assets/images/dgt-experience/dge-content-result3.jpg"),
  // DgeContentBlog: require("../assets/images/dgt-experience/dge-content-blog.jpg"),
  // HomeAboutBeno: require("../assets/images/home/home-about-beno.png"),
  // HomeTriangle: require("../assets/images/home/home-triangle.png"),
  // HomeBanner1: require("../assets/images/home/home-banner1.png"),
  // HomeBanner2: require("../assets/images/home/home-banner2.png"),
  // HomeBanner3: require("../assets/images/home/home-banner3.png"),
  // HrTalentIcon1: require("../assets/images/hr-talent/hr-talent-icon1.png"),
  // HrTalentIcon2: require("../assets/images/hr-talent/hr-talent-icon2.png"),
  // HrTalentIcon3: require("../assets/images/hr-talent/hr-talent-icon3.png"),
  // HrTalentIcon4: require("../assets/images/hr-talent/hr-talent-icon4.png"),

  // FeaturesIcon1: require("../assets/images/home/features-icon1.png"),
  // FeaturesIcon2: require("../assets/images/home/features-icon2.png"),
  // FeaturesIcon3: require("../assets/images/home/features-icon3.png"),
  // FeaturesIcon4: require("../assets/images/home/features-icon4.png"),
  // FeaturesIcon5: require("../assets/images/home/features-icon5.png"),
  // FeaturesIcon6: require("../assets/images/home/features-icon6.png"),
  // FeaturesIcon7: require("../assets/images/home/features-icon7.png"),
  // FeaturesIcon8: require("../assets/images/home/features-icon8.png"),
  // FeaturesIcon9: require("../assets/images/home/features-icon9.png"),
  // FeaturesIcon10: require("../assets/images/home/features-icon10.png"),
  // FeaturesIcon11: require("../assets/images/home/features-icon11.png"),
  // FeaturesIcon12: require("../assets/images/home/features-icon12.png"),
  // FeaturesIcon13: require("../assets/images/home/features-icon13.png"),
  // FeaturesIcon14: require("../assets/images/home/features-icon14.png"),
  // FeaturesIcon15: require("../assets/images/home/features-icon15.png"),
  // FeaturesIcon16: require("../assets/images/home/features-icon16.png"),

  // IndusTrend1: require("../assets/images/home/indus-trend1.jpg"),
  // IndusTrend2: require("../assets/images/home/indus-trend2.jpg"),
  // IndusTrend3: require("../assets/images/home/indus-trend3.jpg"),
  // IndusTrend4: require("../assets/images/home/indus-trend4.jpg"),
  // IndusTrend5: require("../assets/images/home/indus-trend5.jpg"),
  // IndusTrend6: require("../assets/images/home/indus-trend6.jpg"),

  // IndustriesIcon1: require("../assets/images/home-icons/h1.png"),
  // IndustriesIcon2: require("../assets/images/home-icons/h2.png"),
  // IndustriesIcon3: require("../assets/images/home-icons/h3.png"),
  // IndustriesIcon4: require("../assets/images/home-icons/h4.png"),
  // IndustriesIcon5: require("../assets/images/home-icons/h5.png"),
  // IndustriesIcon6: require("../assets/images/home-icons/h6.png"),
  // IndustriesIcon7: require("../assets/images/home-icons/h7.png"),
  // IndustriesIcon8: require("../assets/images/home-icons/h8.png"),
  // IndustriesIcon9: require("../assets/images/home-icons/h9.png"),
  // IndustriesIcon10: require("../assets/images/home-icons/h10.png"),
  // IndustriesIcon11: require("../assets/images/home-icons/h11.png"),
  // IndustriesIcon12: require("../assets/images/home-icons/h12.png"),
  // kotlinIconNew: require("../assets/images/home/kotlin-icon-new.png"),
  // DgeUserBanner: require("../assets/images/dgt-experience/dge-user-banner.jpg"),
  // DgeUserAbout: require("../assets/images/dgt-experience/dge-user-about.jpg"),
  // DgeUserServices1: require("../assets/images/dgt-experience/dge-user-services1.jpg"),
  // HomeCertificate1: require("../assets/images/home/home-certificate1.png"),
  // HomeCertificate2: require("../assets/images/home/home-certificate2.png"),
  // HomeCertificate3: require("../assets/images/home/home-certificate3.png"),
  // HomeCertificate4: require("../assets/images/home/home-certificate4.png"),
  // HomeCertificate5: require("../assets/images/home/home-certificate5.png"),
  // HomeExcellence1: require("../assets/images/home/home-excellence1.png"),
  // HomeExcellence2: require("../assets/images/home/home-excellence2.png"),
  // HomeExcellence3: require("../assets/images/home/home-excellence3.png"),
  // HomeExcellence4: require("../assets/images/home/home-excellence4.png"),
  // HomeContactInnerIMG: require("../assets/images/home/home-contact-inner-img.png"),

  // AboutStory2016: require("../assets/images/main-site/about-story2016.jpg"),
  // AboutStory2022: require("../assets/images/main-site/about-story2022.jpg"),

  // ExcellenceITSupport: require("../assets/images/home/it-supo-home-sec.jpg"),

  // RemoteWork: require("../assets/images/industriesTrends/remote-work.jpg"),
  // OptimizingWorkplace: require("../assets/images/industriesTrends/Optimizing-Workplace-Dynamics.jpg"),
  // RemoteWorkBlog: require("../assets/images/industriesTrends/remote-work-blog.jpg"),
  // FutureAIBanner: require("../assets/images/industriesTrends/futureAI-banner.jpg"),
  // ForefrontOfInnovation: require("../assets/images/industriesTrends/forefront-of-innovation.jpg"),
  // ShapingFutureBlog: require("../assets/images/industriesTrends/shapingFutureBlog.jpg"),
  // EducationDigital: require("../assets/images/industriesTrends/education-in-digital-age.jpg"),
  // DigitizationAdvanceKnowledge: require("../assets/images/industriesTrends/digitization-advance-knowledge.jpg"),
  // EduDigitalAgeBlog: require("../assets/images/industriesTrends/education-in-digital-age.jpg"),
  // HealthcareTechInnovation: require("../assets/images/industriesTrends/healthcare-tech-innovation.jpg"),
  // TechnologyModernHealth: require("../assets/images/industriesTrends/technology-modern-health.jpg"),
  // HealthcareInnovationBlog: require("../assets/images/industriesTrends/healthcare-innovations-blog.jpg"),
  // HomeCtaBg: require("../assets/images/home/home-cta-bg.png"),
  // VrEntertainment: require("../assets/images/industriesTrends/vr-entertainment.png"),
  // VrEntertainmentAbout: require("../assets/images/industriesTrends/vr-entertainment-about.jpg"),
  // FintechBanner: require("../assets/images/industriesTrends/fintech-banner.png"),
  // FintechAbout: require("../assets/images/industriesTrends/fintech-about.jpg"),
  // BpoMedicalBilling: require("../assets/images/bpo/bpo-medical-billing.jpg"),
  // BfsiBpoAbout: require("../assets/images/industry/bfsi/bfsi-bpo-about.jpg"),
  // BfsiManageItBanner: require("../assets/images/industry/bfsi/bfsi-manage-it-banner.jpg"),
  // BfsiManageItAbout: require("../assets/images/industry/bfsi/bfsi-manage-it-about.jpg"),
  // BfsiManageItServices1: require("../assets/images/industry/bfsi/bfsi-manage-it-services1.jpg"),
  // BfsiManageItServices2: require("../assets/images/industry/bfsi/bfsi-manage-it-services2.jpg"),
  // BfsiManageItServices3: require("../assets/images/industry/bfsi/bfsi-manage-it-services3.jpg"),
  // BfsiManageItServices4: require("../assets/images/industry/bfsi/bfsi-manage-it-services4.jpg"),
  // BfsiManageItServices6: require("../assets/images/industry/bfsi/bfsi-manage-it-services6.jpg"),
  // BfsiCyberAbout: require("../assets/images/industry/bfsi/bfsi-cyber-about.jpg"),
  // BfsiCyberServices2: require("../assets/images/industry/bfsi/bfsi-cyber-services2.jpg"),
  // BfsiCyberServices3: require("../assets/images/industry/bfsi/bfsi-cyber-services3.jpg"),
  // BfsiCyberServices4: require("../assets/images/industry/bfsi/bfsi-cyber-services4.jpg"),
  // BfsiCyberServices5: require("../assets/images/industry/bfsi/bfsi-cyber-services5.jpg"),
  // BfsiCyberServices6: require("../assets/images/industry/bfsi/bfsi-cyber-services6.jpg"),
  // BfsiCloudServices1: require("../assets/images/industry/bfsi/bfsi-cloud-services1.jpg"),
  // BfsiCloudServices2: require("../assets/images/industry/bfsi/bfsi-cloud-services2.jpg"),
  // BfsiCloudServices4: require("../assets/images/industry/bfsi/bfsi-cloud-services4.jpg"),
  // BfsiCloudServices5: require("../assets/images/industry/bfsi/bfsi-cloud-services5.jpg"),
  // BfsiHrTalentServices2: require("../assets/images/industry/bfsi/bfsi-hr-talent-services1.jpg"),
  // BfsiHrTalentServices1: require("../assets/images/industry/bfsi/bfsi-hr-talent-services2.jpg"),
  // BfsiHrTalentServices3: require("../assets/images/industry/bfsi/bfsi-hr-talent-services3.jpg"),
  // BfsiHrTalentServices4: require("../assets/images/industry/bfsi/bfsi-hr-talent-services4.jpg"),
  // BfsiHrTalentAbout2: require("../assets/images/industry/bfsi/bfsi-hr-talent-about2.jpg"),
  // ItBannerNew: require("../assets/images/industry/it/it-banner-new.jpg"),
  // ItesManageItBanner: require("../assets/images/industry/ites/ites-manage-it-banner.jpg"),
  // ItesManageItAbout: require("../assets/images/industry/ites/ites-manage-it-about.jpg"),
  // ItesManageItServices1: require("../assets/images/industry/ites/ites-manage-it-services1.jpg"),
  // ItesManageItServices2: require("../assets/images/industry/ites/ites-manage-it-services2.jpg"),
  // ItesManageItServices3: require("../assets/images/industry/ites/ites-manage-it-services3.jpg"),
  // ItesManageItServices4: require("../assets/images/industry/ites/ites-manage-it-services4.jpg"),
  // ItesManageItServices6: require("../assets/images/industry/ites/ites-manage-it-services6.jpg"),
  // ItesCyberBanner: require("../assets/images/industry/ites/ites-cyber-banner.jpg"),
  // SoftwareDesignBannerNew: require("../assets/images/application-services/software-design-banner-new.jpg"),
  // HrTalentRetailMain: require("../assets/images/hr-talent/hr-talent-retail-main.jpg"),
  // HrTalentManufacturingMain: require("../assets/images/hr-talent/hr-talent-manufacturing-main.jpg"),
  // ManufacturingHrServices1: require("../assets/images/hr-talent/manufacturing-hr-servicces1.jpg"),
  // ManufacturingHrServices2: require("../assets/images/hr-talent/manufacturing-hr-servicces2.jpg"),
  // ManufacturingHrServices3: require("../assets/images/hr-talent/manufacturing-hr-servicces3.jpg"),
  // ManufacturingHrServices4: require("../assets/images/hr-talent/manufacturing-hr-servicces4.jpg"),
  // ManufacturingHrServices5: require("../assets/images/hr-talent/manufacturing-hr-servicces5.jpg"),
  // ManufacturingHrServices6: require("../assets/images/hr-talent/manufacturing-hr-servicces6.jpg"),
  // ManufacturingHrServices7: require("../assets/images/hr-talent/manufacturing-hr-servicces7.jpg"),
  // ManufacturingHrServices8: require("../assets/images/hr-talent/manufacturing-hr-servicces8.jpg"),
  // EcomHrServices1: require("../assets/images/hr-talent/ecom-hr-services1.jpg"),
  // EcomHrServices2: require("../assets/images/hr-talent/ecom-hr-services2.jpg"),

  // DgeDgmBanner: require("../assets/images/dgt-experience/dge-dgm-banner.png"),
  // DgeDgmGlobe: require("../assets/images/dgt-experience/dge-dgm-globe.png"),
  // DgeUserServices1New: require("../assets/images/dgt-experience/dge-user-services1-new.jpg"),
  // DgeUserServices2New: require("../assets/images/dgt-experience/dge-user-services2-new.jpg"),
  // DgeUserServices3New: require("../assets/images/dgt-experience/dge-user-services3-new.jpg"),
  // DgeUserServices4New: require("../assets/images/dgt-experience/dge-user-services4-new.jpg"),
  // DgeMultiCultureSerives1: require("../assets/images/dgt-experience/dge-multi-culture-services1.jpg"),
  // DgeMultiCultureSerives2: require("../assets/images/dgt-experience/dge-multi-culture-services2.jpg"),
  // DgeMultiCultureSerives3: require("../assets/images/dgt-experience/dge-multi-culture-services3.jpg"),
  // DgeMultiCultureSerives4: require("../assets/images/dgt-experience/dge-multi-culture-services4.jpg"),
  // DgeWebsiteBanner: require("../assets/images/dgt-experience/dge-website-localization-banner.jpg"),
  // DgeWebsiteServices1: require("../assets/images/dgt-experience/dge-website-localization-services1.jpg"),
  // DgeWebsiteServices2: require("../assets/images/dgt-experience/dge-website-localization-services2.jpg"),
  // DgeWebsiteServices3: require("../assets/images/dgt-experience/dge-website-localization-services3.jpg"),
  // DgeWebsiteServices4: require("../assets/images/dgt-experience/dge-website-localization-services4.jpg"),
  // DgeWebsiteServices5: require("../assets/images/dgt-experience/dge-website-localization-services5.jpg"),
  // DgeWebsiteServices6: require("../assets/images/dgt-experience/dge-website-localization-services6.jpg"),
  // DgeVirtualBanner: require("../assets/images/dgt-experience/dge-virtual-banner.jpg"),
  // DgeVirtualServices1: require("../assets/images/dgt-experience/dge-virtual-services1.jpg"),
  // DgeVirtualServices2: require("../assets/images/dgt-experience/dge-virtual-services2.jpg"),
  // DgeVirtualServices3: require("../assets/images/dgt-experience/dge-virtual-services3.jpg"),
  // DgeVirtualServices4: require("../assets/images/dgt-experience/dge-virtual-services4.jpg"),
  // DgeMultiCultureBanner: require("../assets/images/dgt-experience/dge-multi-culture-banner.png"),
  // BpoSalesBanner: require("../assets/images/bpo/bpo-sales-banner.png"),
  // ManageitRobust: require("../assets/images/manageItSupport/manage-it-robust.jpg"),
  // ManageitOptimize: require("../assets/images/manageItSupport/manage-it-optimize.jpg"),
  // ManageItInfraBanner: require("../assets/images/manageItSupport/manage-it-infra-banner.png"),
  // CyberManageServices1: require("../assets/images/CyberSecurity/cyber-manage-services1.jpg"),
  // CyberManageServices2: require("../assets/images/CyberSecurity/cyber-manage-services2.jpg"),
  // CyberManageServices3: require("../assets/images/CyberSecurity/cyber-manage-services3.jpg"),
  // CyberManageServices4: require("../assets/images/CyberSecurity/cyber-manage-services4.jpg"),
  // CyberManagePower: require("../assets/images/CyberSecurity/cyber-manage-power.jpg"),
  // CyberThreatBanner: require("../assets/images/CyberSecurity/cyber-threat-banner.png"),
  // CyberThreatServices1: require("../assets/images/CyberSecurity/cyber-threat-services1.jpg"),
  // CyberThreatServices2: require("../assets/images/CyberSecurity/cyber-threat-services2.jpg"),
  // CyberApsBanner: require("../assets/images/CyberSecurity/cyber-app-banner.png"),
  // CloudInfraBanner: require("../assets/images/CyberSecurity/cyber-cloud-infra-banner.png"),
  // CyberAppServices1: require("../assets/images/CyberSecurity/cyber-app-services1.jpg"),
  // CyberAppServices2: require("../assets/images/CyberSecurity/cyber-app-services2.jpg"),
  // CyberAppServices3: require("../assets/images/CyberSecurity/cyber-app-services3.jpg"),
  // CyberAppServices4: require("../assets/images/CyberSecurity/cyber-app-services4.jpg"),
  // CyberAppServices5: require("../assets/images/CyberSecurity/cyber-app-services5.jpg"),
  // CyberAppServices6: require("../assets/images/CyberSecurity/cyber-app-services6.jpg"),
  // CyberCloudInfraServices1: require("../assets/images/CyberSecurity/cyber-cloud-infra-services1.jpg"),
  // CyberCloudInfraServices2: require("../assets/images/CyberSecurity/cyber-cloud-infra-services2.jpg"),
  // CyberCloudInfraServices3: require("../assets/images/CyberSecurity/cyber-cloud-infra-services3.jpg"),
  // CyberCloudInfraServices4: require("../assets/images/CyberSecurity/cyber-cloud-infra-services4.jpg"),
  // CloudManageBanner: require("../assets/images/cloud/cloud-manage-service-banner.jpg"),
  // CloudManageAbout: require("../assets/images/cloud/cloud-manage-service-about.jpg"),
  // CLoudManageServices1: require("../assets/images/cloud/cloud-manage-services1.jpg"),
  // CLoudManageServices2: require("../assets/images/cloud/cloud-manage-services2.jpg"),
  // CLoudManageServices3: require("../assets/images/cloud/cloud-manage-services3.jpg"),
  // CLoudManageServices4: require("../assets/images/cloud/cloud-manage-services4.jpg"),
  // CLoudManageSecurity1: require("../assets/images/cloud/cloud-manage-security1.jpg"),
  // CLoudManageSecurity2: require("../assets/images/cloud/cloud-manage-security2.jpg"),
  // CLoudManageSecurity3: require("../assets/images/cloud/cloud-manage-security3.jpg"),
  // CloudManageAbout2: require("../assets/images/cloud/cloud-manage-about2.jpg"),
  // SoftwareDesignThink1: require("../assets/images/application-services/software-design-think1.jpg"),
  // SoftwareDesignThink2: require("../assets/images/application-services/software-design-think2.jpg"),
  // SoftwareAndroid: require("../assets/images/application-services/software-android.jpg"),
  // SoftwareQualityBanner: require("../assets/images/application-services/software-quality-banner.jpg"),
  // SoftwareQualityThink1: require("../assets/images/application-services/software-quality-think1.jpg"),
  // SoftwareQualityThink2: require("../assets/images/application-services/software-quality-think2.jpg"),
  // SoftwareQualityThink3: require("../assets/images/application-services/software-quality-think3.jpg"),
  // SoftwareAnalysis: require("../assets/images/application-services/software-lifecycle-analysis.jpg"),
  // SoftwareArchitecture: require("../assets/images/application-services/software-lifecycle-architecture.jpg"),
  // SoftwareLifecycleDecom: require("../assets/images/application-services/software-lifecycle-decom.jpg"),
  // HrStaffingBuildingNew: require("../assets/images/hr-talent/hr-talent-building-new.jpg"),
  // SoftwareLifeCycleBannerNew: require("../assets/images/application-services/software-lifecycle-banner-new.jpg"),
  // DgeChallange: require("../assets/images/dgt-experience/dge-challange.jpg"),
  // DgeCyberLeading: require("../assets/images/dgt-experience/dge-cyber-leading.jpg"),
  // ItesDgtAboutNew: require("../assets/images/industry/ites/ites-dgt-about-new.jpg"),
  // ItesCyberAbout: require("../assets/images/industry/ites/ites-cyber-about.jpg"),
  // ItesManageIcon1: require("../assets/images/industry/ites/ites-manage-icon1.png"),
  // ItesManageIcon2: require("../assets/images/industry/ites/ites-manage-icon2.png"),
  // ItesManageIcon3: require("../assets/images/industry/ites/ites-manage-icon3.png"),
  // ItesManageIcon4: require("../assets/images/industry/ites/ites-manage-icon4.png"),
  // ItesCloudBannerNew: require("../assets/images/industry/ites/ites-cloud-banner-new.jpg"),
  // ItesCloudAbout: require("../assets/images/industry/ites/ites-cloud-about.jpg"),
  // ItesCloudServices1: require("../assets/images/industry/ites/ites-cloud-services1.jpg"),
  // ItesCloudServices2: require("../assets/images/industry/ites/ites-cloud-services2.jpg"),
  // ItesCloudServices3: require("../assets/images/industry/ites/ites-cloud-services3.jpg"),
  // ItesCloudServices4: require("../assets/images/industry/ites/ites-cloud-services4.jpg"),
  // ItesCloudServices5: require("../assets/images/industry/ites/ites-cloud-services5.jpg"),
  // ItesCloudServices6: require("../assets/images/industry/ites/ites-cloud-services6.jpg"),
  // ItesCloudServices7: require("../assets/images/industry/ites/ites-cloud-services7.jpg"),
  // ItesCloudServices8: require("../assets/images/industry/ites/ites-cloud-services8.jpg"),
  // ItesCloudServices9: require("../assets/images/industry/ites/ites-cloud-services9.jpg"),
  // ItesCloudServices10: require("../assets/images/industry/ites/ites-cloud-services10.jpg"),
  // ItesDgeBanner: require("../assets/images/industry/ites/ites-dge-banner.png"),
  // RetailMainBanner: require("../assets/images/industry/retails/retail-main-banner.png"),
  // ItesDgtServices1: require("../assets/images/industry/ites/ites-dgt-services1.jpg"),
  // ItesDgtServices2: require("../assets/images/industry/ites/ites-dgt-services2.jpg"),
  // ItesDgtServices3: require("../assets/images/industry/ites/ites-dgt-services3.jpg"),
  // ItesDgtServices4: require("../assets/images/industry/ites/ites-dgt-services4.jpg"),
  // ItesDgtServices5: require("../assets/images/industry/ites/ites-dgt-services5.jpg"),
  // ItesDgtServices6: require("../assets/images/industry/ites/ites-dgt-services6.jpg"),
  // RetailsBpoAboutNew2: require("../assets/images/industry/retails/retails-bpo-about-new2.jpg"),
  // RetailsBpoBanner: require("../assets/images/industry/retails/retail-bpo-banner.png"),
  // ItesDgeAbout: require("../assets/images/industry/ites/ites-dge-about.jpg"),
  // ItesDgeServices1: require("../assets/images/industry/ites/ites-dge-services1.jpg"),
  // ItesDgeServices2: require("../assets/images/industry/ites/ites-dge-services2.jpg"),
  // ItesDgeServices3: require("../assets/images/industry/ites/ites-dge-services3.jpg"),
  // ItesDgePlanning1: require("../assets/images/industry/ites/ites-dge-planning1.jpg"),
  // ItesDgePlanning2: require("../assets/images/industry/ites/ites-dge-planning2.jpg"),
  // ItesDgePlanning3: require("../assets/images/industry/ites/ites-dge-planning3.jpg"),
  // ItesDgePlanning4: require("../assets/images/industry/ites/ites-dge-planning4.jpg"),
  // RetailsBpoServices1: require("../assets/images/industry/retails/retails-bpo-services1.jpg"),
  // RetailsBpoServices2: require("../assets/images/industry/retails/retails-bpo-services2.jpg"),
  // RetailsBpoServices3: require("../assets/images/industry/retails/retails-bpo-services3.jpg"),
  // RetailsBpoServices4: require("../assets/images/industry/retails/retails-bpo-services4.jpg"),
  // RetailsBpoServices5: require("../assets/images/industry/retails/retails-bpo-services5.jpg"),
  // RetailsBpoServices6: require("../assets/images/industry/retails/retails-bpo-services6.jpg"),
  // RetailsDgtBanner: require("../assets/images/industry/retails/retails-dgt-banner.png"),
  // RetailsApsBanner: require("../assets/images/industry/retails/retails-aps-banner.png"),
  // RetailsDgtAbout: require("../assets/images/industry/retails/retails-dgt-about.jpg"),
  // RetailsDgtServices1: require("../assets/images/industry/retails/retails-dgt-services1.jpg"),
  // RetailsDgtServices2: require("../assets/images/industry/retails/retails-dgt-services2.jpg"),
  // RetailsDgtServices3: require("../assets/images/industry/retails/retails-dgt-services3.jpg"),
  // RetailsDgtServices4: require("../assets/images/industry/retails/retails-dgt-services4.jpg"),
  // RetailsDgtServices5: require("../assets/images/industry/retails/retails-dgt-services5.jpg"),
  // RetailsDgtServices6: require("../assets/images/industry/retails/retails-dgt-services6.jpg"),
  // RetailsManageServices1: require("../assets/images/industry/retails/retails-manage-services1.jpg"),
  // RetailsManageServices2: require("../assets/images/industry/retails/retails-manage-services2.jpg"),
  // RetailsManageServices3: require("../assets/images/industry/retails/retails-manage-services3.jpg"),
  // RetailsManageServices4: require("../assets/images/industry/retails/retails-manage-services4.jpg"),
  // RetailsManageServices5: require("../assets/images/industry/retails/retails-manage-services5.jpg"),
  // RetailsManageServices6: require("../assets/images/industry/retails/retails-manage-services6.jpg"),
  // RetailsManageAbout: require("../assets/images/industry/retails/retails-manage-about.jpg"),
  // RetailsApsAbout: require("../assets/images/industry/retails/retails-aps-about.jpg"),
  // RetailsApsServices1: require("../assets/images/industry/retails/retails-aps-services1.jpg"),
  // RetailsApsServices2: require("../assets/images/industry/retails/retails-aps-services2.jpg"),
  // RetailsApsServices3: require("../assets/images/industry/retails/retails-aps-services23.jpg"),
  // RetailsApsServices4: require("../assets/images/industry/retails/retails-aps-services4.jpg"),
  // RetailsApsServices5: require("../assets/images/industry/retails/retails-aps-services5.jpg"),
  // RetailsApsServices6: require("../assets/images/industry/retails/retails-aps-services6.jpg"),
  // RetailsCyberAbout: require("../assets/images/industry/retails/retails-cyber-about.jpg"),
  // RetailsCyberServices1: require("../assets/images/industry/retails/retails-cyber-services1.jpg"),
  // RetailsCyberServices2: require("../assets/images/industry/retails/retails-cyber-services2.jpg"),
  // RetailsCyberServices3: require("../assets/images/industry/retails/retails-cyber-services3.jpg"),
  // RetailsCyberServices4: require("../assets/images/industry/retails/retails-cyber-services4.jpg"),
  // RetailsManageBanner: require("../assets/images/industry/retails/retails-manage-banner.png"),
  // RetailsCyberBanner: require("../assets/images/industry/retails/retails-cyber-banner.png"),
  // RetailsCloudAbout: require("../assets/images/industry/retails/retails-cloud-about.jpg"),
  // RetailsCloudServices1: require("../assets/images/industry/retails/retails-cloud-services1.jpg"),
  // RetailsCloudServices2: require("../assets/images/industry/retails/retails-cloud-services2.jpg"),
  // RetailsCloudServices3: require("../assets/images/industry/retails/retails-cloud-services3.jpg"),
  // RetailsCloudServices4: require("../assets/images/industry/retails/retails-cloud-services4.jpg"),
  // RetailsCloudServices5: require("../assets/images/industry/retails/retails-cloud-services5.jpg"),
  // RetailsCloudServices6: require("../assets/images/industry/retails/retails-cloud-services6.jpg"),
  // RetailsCloudBanner: require("../assets/images/industry/retails/retails-cloud-banner.png"),
  // EducationMainFaq1: require("../assets/images/industry/retails/education-main-faq1.jpg"),
  // EducationMainFaq2: require("../assets/images/industry/retails/education-main-faq2.jpg"),
  // EducationMainFaq3: require("../assets/images/industry/retails/education-main-faq3.jpg"),
  // EducationMainFaq4: require("../assets/images/industry/retails/education-main-faq4.jpg"),
  // EducationApsBanner: require("../assets/images/industry/education/education-aps-banner.jpg"),
  // EducationApsAbout: require("../assets/images/industry/education/education-aps-about.jpg"),

  // LogisticSmart1: require("../assets/images/industry/logistics/logistic-smart1.jpg"),
  // LogisticSmart2: require("../assets/images/industry/logistics/logistic-smart2.jpg"),
  // LogisticSmart3: require("../assets/images/industry/logistics/logistic-smart3.jpg"),
  // LogisticBpoBanner: require("../assets/images/industry/logistics/logistic-bpo-banner.png"),
  // LogisticBpoAbout: require("../assets/images/industry/logistics/logistic-bpo-about.jpg"),
  // LogisticBpoServices1: require("../assets/images/industry/logistics/logistic-bpo-services1.jpg"),
  // LogisticBpoServices2: require("../assets/images/industry/logistics/logistic-bpo-services2.jpg"),
  // LogisticBpoServices3: require("../assets/images/industry/logistics/logistic-bpo-services3.jpg"),
  // LogisticBpoServices4: require("../assets/images/industry/logistics/logistic-bpo-services4.jpg"),
  // LogisticBpoServices5: require("../assets/images/industry/logistics/logistic-bpo-services5.jpg"),
  // LogisticBpoServices6: require("../assets/images/industry/logistics/logistic-bpo-services6.jpg"),
  // LogisticBpoServices7: require("../assets/images/industry/logistics/logistic-bpo-services7.jpg"),
  // LogisticBpoServices8: require("../assets/images/industry/logistics/logistic-bpo-services8.jpg"),
  // TechnologyBpoAbout: require("../assets/images/industry/technology/technology-bpo-about.jpg"),
  // TechnologyBpoBanner: require("../assets/images/industry/technology/technology-bpo-banner.png"),
  // TechnologyBpoExpertise: require("../assets/images/industry/technology/technology-bpo-expertise.jpg"),
  // TechnologyBpoValue1: require("../assets/images/industry/technology/technology-bpo-value1.jpg"),
  // TechnologyBpoValue2: require("../assets/images/industry/technology/technology-bpo-value2.jpg"),
  // TechnologyBpoValue3: require("../assets/images/industry/technology/technology-bpo-value3.jpg"),
  // TechnologyBpoValue4: require("../assets/images/industry/technology/technology-bpo-value4.jpg"),
  // TechnologyHrBanner: require("../assets/images/industry/technology/technology-hr-banner.png"),
  // TechnologyHrAbout: require("../assets/images/industry/technology/technology-hr-about.jpg"),
  // Dgtfaq1: require("../assets/images/dgt/dgt-faq1.jpg"),
  // Dgtfaq2: require("../assets/images/dgt/dgt-faq2.jpg"),
  // Dgtfaq3: require("../assets/images/dgt/dgt-faq3.jpg"),
  // HealthcaremainBanner: require("../assets/images/industry/health-care/healthcare-main-banner.jpeg.jpg"),
  // EcommerceBpoBanner: require("../assets/images/industry/ecommerce/ecommerce-bpo-banner.jpg"),
  // EcommerceBpoAbout: require("../assets/images/industry/ecommerce/ecommerce-bpo-about.jpg"),
  // EcommerceBpoServices1: require("../assets/images/industry/ecommerce/ecommerce-bpo-services1.jpg"),
  // EcommerceBpoServices2: require("../assets/images/industry/ecommerce/ecommerce-bpo-services2.jpg"),
  // EcommerceBpoServices3: require("../assets/images/industry/ecommerce/ecommerce-bpo-services3.jpg"),
  // EcommerceBpoServices4: require("../assets/images/industry/ecommerce/ecommerce-bpo-services4.jpg"),
  // EcommerceBpoServices5: require("../assets/images/industry/ecommerce/ecommerce-bpo-services5.jpg"),
  // EcommerceBpoServices6: require("../assets/images/industry/ecommerce/ecommerce-bpo-services6.jpg"),
  // EcommerceApsBanner: require("../assets/images/industry/ecommerce/ecommerce-aps-banner.jpg"),
  // EcommerceApsServices1: require("../assets/images/industry/ecommerce/ecommerce-aps-services1.jpg"),
  // EcommerceApsServices2: require("../assets/images/industry/ecommerce/ecommerce-aps-services2.jpg"),
  // EcommerceApsServices3: require("../assets/images/industry/ecommerce/ecommerce-aps-services3.jpg"),
  // EcommerceApsServices4: require("../assets/images/industry/ecommerce/ecommerce-aps-services4.jpg"),
  // EcommerceManageBanner: require("../assets/images/industry/ecommerce/ecommerce-manage-banner.jpg"),
  // EcommerceManageServices1: require("../assets/images/industry/ecommerce/ecommerce-manage-services1.jpg"),
  // EcommerceManageServices2: require("../assets/images/industry/ecommerce/ecommerce-manage-services2.jpg"),
  // EcommerceCyberBanner: require("../assets/images/industry/ecommerce/ecommerce-cyber-banner.jpg"),
  // EcommerceCyberAbout: require("../assets/images/industry/ecommerce/ecommerce-cyber-about.jpg"),
  // EcommerceCyberServices1: require("../assets/images/industry/ecommerce/ecommerce-cyber-services1.jpg"),
  // EcommerceCyberServices2: require("../assets/images/industry/ecommerce/ecommerce-cyber-services2.jpg"),
  // EcommerceCyberServices3: require("../assets/images/industry/ecommerce/ecommerce-cyber-services3.jpg"),
  // EcommerceCloudBanner: require("../assets/images/industry/ecommerce/ecommerece-cloud-banner.jpg"),
  // EcoomerceCloudAbout: require("../assets/images/industry/ecommerce/ecommerce-cloud-about.jpg"),
  // EcoomerceCloudServices1: require("../assets/images/industry/ecommerce/ecommerce-cloud-services1.jpg"),
  // EcoomerceCloudServices2: require("../assets/images/industry/ecommerce/ecommerce-cloud-services2.jpg"),
  // EcoomerceCloudServices3: require("../assets/images/industry/ecommerce/ecommerce-cloud-services3.jpg"),
  // EcommerceHrBanner: require("../assets/images/industry/ecommerce/ecommerce-hr-banner.jpg"),
  // EcommerceHrServices1: require("../assets/images/industry/ecommerce/ecommerce-hr-services1.jpg"),
  // EcommerceHrServices2: require("../assets/images/industry/ecommerce/ecommerce-hr-services2.jpg"),
  // EcommerceDgeBanner: require("../assets/images/industry/ecommerce/ecommerce-dge-banner.jpg"),
  // EcommerceDgeServices1: require("../assets/images/industry/ecommerce/ecommerce-dge-services1.jpg"),
  // EcommerceDgeServices2: require("../assets/images/industry/ecommerce/ecommerce-dge-services2.jpg"),
  // EcommerceDgeServices3: require("../assets/images/industry/ecommerce/ecommerce-dge-services3.jpg"),
  // EcommerceDgeServices4: require("../assets/images/industry/ecommerce/ecommerce-dge-services4.jpg"),
  // EcommerceDgeServices5: require("../assets/images/industry/ecommerce/ecommerce-dge-services5.jpg"),
  // EcommerceDgeServices6: require("../assets/images/industry/ecommerce/ecommerce-dge-services6.jpg"),
  // EcommerceDgeServices8: require("../assets/images/industry/ecommerce/ecommerce-dge-services8.jpg"),
  // EcommerceDgeServices7: require("../assets/images/industry/ecommerce/ecommerce-dge-services7.jpg"),
  // ManufacturingMainFaq1: require("../assets/images/industry/ecommerce/ecommerce-manufacturing-faq1.jpg"),
  // ManufacturingMainFaq2: require("../assets/images/industry/ecommerce/ecommerce-manufacturing-faq2.jpg"),
  // ManufacturingMainFaq3: require("../assets/images/industry/ecommerce/ecommerce-manufacturing-faq3.jpg"),
  // ManufacturingMainFaq4: require("../assets/images/industry/ecommerce/ecommerce-manufacturing-faq4.jpg"),
  // ManufacturingCloudMain: require("../assets/images/industry/ecommerce/manufacturing-cloud-main.jpg"),
  // ManufacturingDgtBanner: require("../assets/images/Manufacturing/manufacturing-dgt-banner.jpg"),
  // ManufacturingDgtAbout: require("../assets/images/Manufacturing/manufacturing-dgt-about.jpg"),
  // ManufacturingDgtTab1: require("../assets/images/Manufacturing/manufacturing-dgt-tab1.jpg"),
  // ManufacturingDgtTab2: require("../assets/images/Manufacturing/manufacturing-dgt-tab2.jpg"),
  // ManufacturingDgtTab3: require("../assets/images/Manufacturing/manufacturing-dgt-tab3.jpg"),
  // ManufacturingDgtTab4: require("../assets/images/Manufacturing/manufacturing-dgt-tab4.jpg"),
  // ManufacturingDgtTab5: require("../assets/images/Manufacturing/manufacturing-dgt-tab5.jpg"),
  // ManufacturingApsBanner: require("../assets/images/Manufacturing/manufacturing-aps-banner.jpg"),
  // ManufacturingApsAbout: require("../assets/images/Manufacturing/manufacturing-aps-about.jpg"),
  // ManufacturingApsPhases: require("../assets/images/Manufacturing/manufacturing-aps-phases.jpg"),
  // ManufacturingCyberBanner: require("../assets/images/Manufacturing/manufacturing-cyber-banner.jpg"),
  // ManufacturingCyberAbout: require("../assets/images/Manufacturing/manufacturing-cyber-about.jpg"),
  // ManufacturingCyberServices1: require("../assets/images/Manufacturing/manufacturing-cyber-services1.jpg"),
  // ManufacturingCyberServices2: require("../assets/images/Manufacturing/manufacturing-cyber-services2.jpg"),
  // ManufacturingCyberServices3: require("../assets/images/Manufacturing/manufacturing-cyber-services3.jpg"),
  // ManufacturingCyberServices4: require("../assets/images/Manufacturing/manufacturing-cyber-services4.jpg"),
  // ManufacturingCyberServices5: require("../assets/images/Manufacturing/manufacturing-cyber-services5.jpg"),
  // ManufacturingCyberServices6: require("../assets/images/Manufacturing/manufacturing-cyber-services6.jpg"),
  // ManufacturingCloudBanner: require("../assets/images/Manufacturing/manufacturing-cloud-banner.jpg"),
  // ManufacturingCloudAbout: require("../assets/images/Manufacturing/manufacturing-cloud-about.jpg"),
  // ManufacturingCloudTab1: require("../assets/images/Manufacturing/manufacturing-cloud-tab1.jpg"),
  // ManufacturingCloudTab2: require("../assets/images/Manufacturing/manufacturing-cloud-tab2.jpg"),
  // ManufacturingCloudTab3: require("../assets/images/Manufacturing/manufacturing-cloud-tab3.jpg"),
  // ManufacturingCloudTab4: require("../assets/images/Manufacturing/manufacturing-cloud-tab4.jpg"),
  // TravelBpoBanner: require("../assets/images/industry/travel/travel-bpo-banner.jpg"),
  // TravelBpoAbout: require("../assets/images/industry/travel/travel-bpo-about.jpg"),
  // ManufacturingManageEvolving1: require("../assets/images/Manufacturing/manufacturing-evolving1.png"),
  // ManufacturingManageEvolving2: require("../assets/images/Manufacturing/manufacturing-evolving2.png"),
  // ManufacturingManageBanner: require("../assets/images/Manufacturing/manufacturing-manage-banner.jpg"),
  // ManufacturingManageAbout: require("../assets/images/Manufacturing/manufacturing-manage-about.jpg"),
  // TravelMainAbout: require("../assets/images/industry/travel/travell-main-about.jpg"),
  // TravelApsBanner: require("../assets/images/industry/travel/travel-aps-banner.jpg"),
  // TravelDgeBanner: require("../assets/images/industry/travel/travel-dge-banner.jpg"),
  // TravelApsAbout: require("../assets/images/industry/travel/travel-aps-about.jpg"),
  // TravelApsAbout2: require("../assets/images/industry/travel/travel-aps-about2.jpg"),
  // TravelDgeAbout2: require("../assets/images/industry/travel/travel-dge-about2.jpg"),
  // TravelDgeAbout: require("../assets/images/industry/travel/travel-dge-about.jpg"),
  // AboutMainBanner: require("../assets/images/main-site/about-main-banner-new.png.jpg"),

  // BpoServe8: require('../')
};
export default IMG;
