import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IMG from "./ImagesOld2";
import {
  faArrowUpLong,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaChevronUp } from "react-icons/fa";

import $ from "jquery";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import BlogContext from "../context/blogContext";

const Footer = () => {
  const { blogData, headerFooterData } = useContext(BlogContext);
  const [showButton, setShowButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const controller = new AbortController();
  const isNameValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(first_name);
  };
  const isEmailValid = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isMobileNumberValid = () => {
    const phonePattern = /^\d{1,14}$/;
    return phonePattern.test(phone);
  };
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const scrollThreshold = window.innerHeight;

      if (scrollPosition >= scrollThreshold) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { first_name, email, phone, message } = formData;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "https://admin.benosupport.com/api/contact-add";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    try {
      setLoading(true);
      const response = await fetch(url, options);
      const responseData = await response.json();

      setFormData({
        first_name: "",
        email: "",
        phone: "",
        message: "",
      });
      setMsg("Message Sent Successfully Thanks for Contact Us.");
      setTimeout(() => {
        setLoading(false);
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("Failed to send message. Please try again later.");
      setTimeout(() => {
        setLoading(false);
        setMsg(null);
      }, 3000);
    }
  };

  $("#video-play-model-beno").on("shown.bs.modal", function () {
    $("#popupVideo")[0].play();
  });
  $("#video-play-model-beno").on("hidden.bs.modal", function () {
    $("#popupVideo")[0].pause();
  });

  // js code scrollup button code
  let calcScrollValue = () => {
    let scrollProgress = document.getElementById("progress");
    let pos = document.documentElement.scrollTop;
    let calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrollValue = Math.round((pos * 100) / calcHeight);
    if (pos > 100) {
      scrollProgress.style.display = "grid";
    } else {
      scrollProgress.style.display = "none";
    }
    scrollProgress.addEventListener("click", () => {
      document.documentElement.scrollTop = 0;
    });
    scrollProgress.style.background = `conic-gradient(#F1742D  ${scrollValue}%, #d7d7d7 ${scrollValue}%)`;
  };
  window.onscroll = calcScrollValue;
  window.onload = calcScrollValue;

  return (
    <>
      <footer className="inners-footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6" id="footer-first-col">
                <div
                  id="libo_about_widget_two-5"
                  className="widget footer-widget libo-widget-about-two"
                >
                  <div className="footer-widget widget">
                    <div className="about_us_widget">
                      <a href="#logo" className="footer-logo">
                        <img src={headerFooterData.footer_logo} alt="" />
                      </a>
                      <p className="about_us_widget_para">
                        {headerFooterData.footer_desc}
                      </p>
                      <div className="footer-socail-icons">
                        <ul className="social_share">
                          <li className="single-info-item">
                            <a
                              href={headerFooterData.linkedin_url}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                          </li>
                          <li className="single-info-item">
                            <a
                              href={headerFooterData.youtube_url}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faYoutube} />
                            </a>
                          </li>
                          <li className="single-info-item">
                            <a
                              href={headerFooterData.twitter_url}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faXTwitter} />
                            </a>
                          </li>
                          <li className="single-info-item">
                            <a
                              href={headerFooterData.facebook_url}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                          </li>
                          <li className="single-info-item">
                            <a
                              href={headerFooterData.instagram_url}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faInstagram} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6" id="footer-third-col">
                <div
                  id="nav_menu-3"
                  className="widget footer-widget widget_nav_menu"
                >
                  <h4 className="widget-title">Company</h4>
                  <div className="menu-services-link-container">
                    <ul id="menu-services-link">
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/about-beno">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          About Beno
                        </Link>
                      </li>

                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/careers">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Careers
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/sitemap">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Sitemap
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/terms-condition">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Terms & Conditions
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/privacy-policy">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6" id="footer-second-col">
                <div
                  id="nav_menu-2"
                  className="widget footer-widget widget_nav_menu"
                >
                  <h4 className="widget-title">Services</h4>
                  <div className="menu-services-link-container">
                    <ul id="menu-services-link">
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/cloud-services">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Cloud
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/software-services">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Software
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/it-consulting-support">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          IT Support
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/cyber-security">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Cyber Security
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/bpo-service">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Business Process
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/digital-experience">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Digital Experience
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/digital-transformation">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Digital Transformation
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6" id="footer-third-col">
                <div
                  id="nav_menu-3"
                  className="widget footer-widget widget_nav_menu"
                >
                  <h4 className="widget-title">Industries</h4>
                  <div className="menu-services-link-container">
                    <ul id="menu-services-link">
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/ites">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          ITES
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/bfsi">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          BFSI
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/retail">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Retail
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/logistics">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Logistics
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/education">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Education
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/technology">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Technology
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-service">
                        <Link to="/manufacturing">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="footer-lists-icons"
                          />
                          Manufacturing
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6" id="footer-fourth-col">
                <div
                  id="libo_popular_posts-7"
                  className="widget footer-widget widget_libo_popular_posts"
                >
                  <h4 className="widget-title">Recent News</h4>
                  <ul className="recent_post_item">
                    {blogData &&
                      blogData.length > 0 &&
                      blogData.slice(0, 2).map((i) => (
                        <li className="single-recent-post-item" key={i.id}>
                          <div className="thumb">
                            <Link to={`/blog/${i.blog_slug}`}>
                              <img src={i.blog_img} alt="Img." />
                            </Link>
                          </div>
                          <div className="content">
                            <p className="title">
                              <Link to={`/blog/${i.blog_slug}`}>
                                {i.title.split(" ").slice(0, 5).join(" ") +
                                  "..."}
                              </Link>
                            </p>
                            <span className="time">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.72763 0.727265V1.49089C8.48764 1.46908 8.24764 1.45453 8.00037 1.45453C7.7531 1.45453 7.5131 1.46908 7.2731 1.49089V0.727265C7.2731 0.534383 7.34973 0.3494 7.48611 0.213011C7.6225 0.0766224 7.80749 0 8.00037 0C8.19325 0 8.37823 0.0766224 8.51462 0.213011C8.65101 0.3494 8.72763 0.534383 8.72763 0.727265ZM0.676368 6.22586C1.48894 4.40236 2.94737 2.94393 4.77087 2.13136C4.24983 1.69275 3.59015 1.4531 2.90907 1.455C2.13754 1.455 1.39761 1.76149 0.852056 2.30704C0.306501 2.8526 1.1257e-05 3.59253 1.1257e-05 4.36406C-0.00189262 5.04513 0.237759 5.70482 0.676368 6.22586ZM15.3236 6.22586C15.7622 5.70482 16.0018 5.04513 15.9999 4.36406C15.9999 3.59253 15.6934 2.8526 15.1479 2.30704C14.6023 1.76149 13.8624 1.455 13.0909 1.455C12.4098 1.4531 11.7501 1.69275 11.2291 2.13136C13.0526 2.94393 14.511 4.40236 15.3236 6.22586ZM4.36396 4.01215C5.44034 3.29294 6.70582 2.90906 8.00038 2.90906C9.73633 2.90906 11.4012 3.59866 12.6287 4.82616C13.8562 6.05366 14.5458 7.7185 14.5458 9.45445C14.5458 10.749 14.1619 12.0145 13.4427 13.0909C12.7235 14.1673 11.7012 15.0062 10.5052 15.5016C9.30918 15.997 7.99312 16.1266 6.72344 15.8741C5.45376 15.6215 4.28748 14.9981 3.37209 14.0827C2.4567 13.1673 1.83331 12.0011 1.58076 10.7314C1.3282 9.46171 1.45782 8.14565 1.95323 6.94963C2.44863 5.75362 3.28757 4.73137 4.36396 4.01215ZM8.51456 9.96862C8.65096 9.83227 8.7276 9.64731 8.72765 9.45445V5.09085C8.72765 4.89797 8.65102 4.71299 8.51463 4.5766C8.37825 4.44021 8.19326 4.36359 8.00038 4.36359C7.8075 4.36359 7.62251 4.44021 7.48613 4.5766C7.34974 4.71299 7.27311 4.89797 7.27311 5.09085V9.15336L5.30441 11.1221C5.17193 11.2592 5.09863 11.4429 5.10028 11.6336C5.10194 11.8243 5.17843 12.0067 5.31327 12.1416C5.44811 12.2764 5.63052 12.3529 5.8212 12.3545C6.01189 12.3562 6.1956 12.2829 6.33276 12.1504L8.51456 9.96862Z"
                                  fill="#CD4409"
                                />
                              </svg>
                              {i.blog_date}
                            </span>
                          </div>
                        </li>
                      ))}

                    <li className="footer-partners mt-3">
                      <img src={IMG.FooterPartners} alt="Img." />
                    </li>
                    <li className="mt-3">
                      <img src={IMG.FooterPartners2} alt="Img." />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------- copy right footer ----------------------- */}
        <div className="copy-right-div-footer-beno">
          <div className="container" id="footer-main-container-sec-beno">
            <div className="row" id="footer-copy-row-beno">
              <div className="col-md-6" id="footer-sitemap-div-beno-col">
                <p className="copy-right-line-beno">
                  ©{new Date().getFullYear()} BenoSupport, All Rights Reserved
                </p>
              </div>
              <div className="col-md-6" id="footer-sitemap-div-beno">
                <Link to="/terms-condition">Terms & Conditions | </Link>
                <Link to="/privacy-policy"> Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/*----------------= site scroll code =-------- */}
      <div id="progress">
        <span id="progress-value">
          <FontAwesomeIcon icon={faArrowUpLong} />
        </span>
      </div>

      <FloatingContactBtn
        showButton={showButton}
        open={open}
        setOpen={setOpen}
        handleSubmit={handleSubmit}
        first_name={first_name}
        email={email}
        phone={phone}
        message={message}
        handleChange={handleChange}
        isEmailValid={isEmailValid}
        isMobileNumberValid={isMobileNumberValid}
        isNameValid={isNameValid}
        msg={msg}
      />
    </>
  );
};

export default Footer;

export const FloatingContactBtn = ({
  showButton,
  open,
  setOpen,
  handleSubmit,
  first_name,
  email,
  phone,
  message,
  handleChange,
  isEmailValid,
  isMobileNumberValid,
  isNameValid,
  msg,
}) => (
  <>
    <div
      className={`floating-contact-btn ${
        open ? "floating-contact-btn-comes" : ""
      }`}
      style={{ display: showButton ? "block" : "none" }}
    >
      <a onClick={() => setOpen(!open)}>
        {open ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.33em"
            height="1.33em"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        ) : (
          <FaChevronUp />
        )}{" "}
        &nbsp; Request a call
      </a>{" "}
      {open && (
        <div className="home-floating-form">
          <form onSubmit={handleSubmit}>
            <div className="floating-form-msg">
              <h3>Have any query? Let's get in touch!</h3>
              <p>
                We're here to help with any queries you may have. Get in touch.
              </p>
            </div>
            <div>
              <input
                type="text"
                placeholder="Name"
                name="first_name"
                value={first_name}
                onChange={handleChange}
                required
              />
              {first_name?.trim().length > 0 && !isNameValid() && (
                <span style={{ fontSize: "12px", color: "red" }}>
                  Name must contain only letters.
                </span>
              )}
            </div>
            <div>
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={handleChange}
                required
              />{" "}
              {email.trim().length > 0 && !isEmailValid() && (
                <span style={{ fontSize: "12px", color: "red" }}>
                  Please enter a valid email.
                </span>
              )}
            </div>
            <div>
              <input
                type="text"
                placeholder="Phone"
                name="phone"
                value={phone}
                onChange={handleChange}
                required
              />{" "}
              {phone.trim().length > 0 && !isMobileNumberValid() && (
                <span style={{ fontSize: "12px", color: "red" }}>
                  Mobile number must be at least 10 digits.
                </span>
              )}
            </div>

            <div className="floating-form-msg-textarea">
              <input
                type="text"
                placeholder="Message"
                name="message"
                value={message}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <button type="submit">Send</button>
            </div>
          </form>
          <div className="home-notification-msg">
            {msg && <span className="text-center">{msg}</span>}
          </div>
        </div>
      )}
    </div>
  </>
);
